* {
  min-width: 0;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-size: 16px;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  padding: 0;
  margin: 0;
  color: #1d1d1d;
}

p,
h1,
h2,
h3,
h4,
h5,
ul {
  margin: 0;
}

.h1,
.h2,
.h3,
.h4,
.h5 {
  margin: 0;
}

dl,
dd,
dt {
  margin: 0;
  padding: 0;
}

ul {
  margin-bottom: 0;
  list-style-type: none;
  padding-left: 0;
}

a {
  text-decoration: none;
}
a:hover, a:active, a:focus, a:visited {
  text-decoration: none;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

input,
textarea {
  outline: none;
}
input:focus,
textarea:focus {
  outline: none;
}
input:active,
textarea:active {
  outline: none;
}

textarea {
  resize: none;
}

/* Make images easier to work with */
img,
picture {
  max-width: 100%;
  display: block;
}

/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }
  *,
  *::before,
  *::after {
    -webkit-animation-duration: 0.01ms !important;
            animation-duration: 0.01ms !important;
    -webkit-animation-iteration-count: 1 !important;
            animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}
/*
@font-face {
    font-family: 'HelveticaNeue';
    src: url($s3helv + 'HelveticaNeueCyr-Bold.eot');
    src: url($s3helv + 'HelveticaNeueCyr-Bold.eot?#iefix') format('embedded-opentype'),
        url($s3helv + 'HelveticaNeueCyr-Bold.woff2') format('woff2'),
        url($s3helv + 'HelveticaNeueCyr-Bold.woff') format('woff'),
        url($s3helv + 'HelveticaNeueCyr-Bold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    // descent-override: 0%;
}

@font-face {
    font-family: 'HelveticaNeue';
    src: url($s3helv + 'HelveticaNeueCyr-Light.eot');
    src: url($s3helv + 'HelveticaNeueCyr-Light.eot?#iefix') format('embedded-opentype'),
        url($s3helv + 'HelveticaNeueCyr-Light.woff2') format('woff2'),
        url($s3helv + 'HelveticaNeueCyr-Light.woff') format('woff'),
        url($s3helv + 'HelveticaNeueCyr-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    // descent-override: 0%;
}

@font-face {
    font-family: 'HelveticaNeue';
    src: url($s3helv + 'HelveticaNeueCyr-Medium.eot');
    src: url($s3helv + 'HelveticaNeueCyr-Medium.eot?#iefix') format('embedded-opentype'),
        url($s3helv + 'HelveticaNeueCyr-Medium.woff2') format('woff2'),
        url($s3helv + 'HelveticaNeueCyr-Medium.woff') format('woff'),
        url($s3helv + 'HelveticaNeueCyr-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    // descent-override: 0%;
}

@font-face {
    font-family: 'HelveticaNeue';
    src: url($s3helv + 'HelveticaNeueCyr-Roman.eot');
    src: url($s3helv + 'HelveticaNeueCyr-Roman.eot?#iefix') format('embedded-opentype'),
        url($s3helv + 'HelveticaNeueCyr-Roman.woff2') format('woff2'),
        url($s3helv + 'HelveticaNeueCyr-Roman.woff') format('woff'),
        url($s3helv + 'HelveticaNeueCyr-Roman.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
    descent-override: 0%;
    line-gap-override: 35%;
}
*/
/*
@font-face {
    font-family: 'HelveticaNeue';
    font-style: normal;
    font-weight: 400;
    src: local('Helvetica Neue Roman'),
            local('HelveticaNeue-Roman'), 
            url(https://fonts.cdnfonts.com/s/62895/HelveticaNeueRoman.woff) format('woff');
    //ascent-override: 30%;
    //descent-override: 0%;
    //line-gap-override: 0%;
    // line-gap-override: 35%;
}
//local('Helvetica Neue Roman'),
//local('HelveticaNeue-Roman'), 

@font-face {
    font-family: 'HelveticaNeue';
    font-style: italic;
    font-weight: 400;
    src: url(https://fonts.cdnfonts.com/s/62895/HelveticaNeueItalic.woff) format('woff');
    // descent-override: 0%;
    // line-gap-override: 35%;
}
//local('Helvetica Neue Italic'), 
//local('HelveticaNeue-Italic'), 


@font-face {
    font-family: 'HelveticaNeue';
    font-style: normal;
    font-weight: 100;
    src: url(https://fonts.cdnfonts.com/s/62895/HelveticaNeueUltraLight.woff) format('woff');
    descent-override: 0%;
    line-gap-override: 35%;
}
//local('Helvetica Neue Ultra Light'), 
//local('HelveticaNeue-UltraLight'), 


@font-face {
    font-family: 'HelveticaNeue';
    font-style: italic;
    font-weight: 100;
    src: url(https://fonts.cdnfonts.com/s/62895/HelveticaNeueUltraLightItalic.woff) format('woff');
    descent-override: 0%;
    line-gap-override: 35%;
}
//local('Helvetica Neue Ultra Light Italic'), 
//local('HelveticaNeue-UltraLightItalic'), 


@font-face {
    font-family: 'HelveticaNeue';
    font-style: normal;
    font-weight: 200;
    src: url(https://fonts.cdnfonts.com/s/62895/HelveticaNeueThin.woff) format('woff');
    descent-override: 0%;
    line-gap-override: 35%;
}
//local('Helvetica Neue Thin'), 
//local('HelveticaNeue-Thin'), 


@font-face {
    font-family: 'HelveticaNeue';
    font-style: italic;
    font-weight: 200;
    src: url(https://fonts.cdnfonts.com/s/62895/HelveticaNeueThinItalic.woff) format('woff');
    descent-override: 0%;
    line-gap-override: 35%;
}
//local('Helvetica Neue Thin Italic'), 
//local('HelveticaNeue-ThinItalic'), 


@font-face {
    font-family: 'HelveticaNeue';
    font-style: normal;
    font-weight: 300;
    src: url(https://fonts.cdnfonts.com/s/62895/HelveticaNeueLight.woff) format('woff');
    descent-override: 0%;
    line-gap-override: 35%;
}
//local('Helvetica Neue Light'), 
//local('HelveticaNeue-Light'), 


@font-face {
    font-family: 'HelveticaNeue';
    font-style: italic;
    font-weight: 300;
    src: url(https://fonts.cdnfonts.com/s/62895/HelveticaNeueLightItalic.woff) format('woff');
    descent-override: 0%;
    line-gap-override: 35%;
}
//local('Helvetica Neue Light Italic'), 
//local('HelveticaNeue-LightItalic'), 


@font-face {
    font-family: 'HelveticaNeue';
    font-style: normal;
    font-weight: 500;
    src: url(https://fonts.cdnfonts.com/s/62895/HelveticaNeueMedium.woff) format('woff');
    descent-override: 0%;
    line-gap-override: 35%;
}
//local('Helvetica Neue Medium'), 
//local('HelveticaNeue-Medium'), 


@font-face {
    font-family: 'HelveticaNeue';
    font-style: italic;
    font-weight: 500;
    src: url(https://fonts.cdnfonts.com/s/62895/HelveticaNeueMediumItalic.woff) format('woff');
    descent-override: 0%;
    line-gap-override: 35%;
}
//local('Helvetica Neue Medium Italic'), 
//local('HelveticaNeue-MediumItalic'), 


@font-face {
    font-family: 'HelveticaNeue';
    font-style: normal;
    font-weight: 700;
    src: url(https://fonts.cdnfonts.com/s/62895/HelveticaNeueBold.woff) format('woff');
    descent-override: 0%;
    line-gap-override: 35%;
}
//local('Helvetica Neue Bold'), 
//local('HelveticaNeue-Bold'), 


@font-face {
    font-family: 'HelveticaNeue';
    font-style: italic;
    font-weight: 700;
    src: url(https://fonts.cdnfonts.com/s/62895/HelveticaNeueBoldItalic.woff) format('woff');
    descent-override: 0%;
    line-gap-override: 35%;
}
//local('Helvetica Neue Bold Italic'), 
//local('HelveticaNeue-BoldItalic'), 


@font-face {
    font-family: 'HelveticaNeue';
    font-style: normal;
    font-weight: 800;
    src: url(https://fonts.cdnfonts.com/s/62895/HelveticaNeueHeavy.woff) format('woff');
    descent-override: 0%;
    line-gap-override: 35%;
}
//local('Helvetica Neue Heavy'), 
//local('HelveticaNeue-Heavy'), 


@font-face {
    font-family: 'HelveticaNeue';
    font-style: italic;
    font-weight: 800;
    src: url(https://fonts.cdnfonts.com/s/62895/HelveticaNeueHeavyItalic.woff) format('woff');
    descent-override: 0%;
    line-gap-override: 35%;
}
//local('Helvetica Neue Heavy Italic'), 
//local('HelveticaNeue-HeavyItalic'), 


@font-face {
    font-family: 'HelveticaNeue';
    font-style: normal;
    font-weight: 900;
    src: url(https://fonts.cdnfonts.com/s/62895/HelveticaNeueBlack.woff) format('woff');
    descent-override: 0%;
    line-gap-override: 35%;
}
//local('Helvetica Neue Black'), 
//local('HelveticaNeue-Black'), 


@font-face {
    font-family: 'HelveticaNeue';
    font-style: italic;
    font-weight: 900;
    src: url(https://fonts.cdnfonts.com/s/62895/HelveticaNeueBlackItalic.woff) format('woff');
    descent-override: 0%;
    line-gap-override: 35%;
}
//local('Helvetica Neue Black Italic'),
//local('HelveticaNeue-BlackItalic'),
*/
/*
@font-face {
    font-family: 'HelveticaNeue';
    font-style: normal;
    font-weight: 100;
    src:local('HelveticaNeueUltraLight'), 
        local('HelveticaNeue-Ultra-Light'), 
        local('Helvetica Neue Ultra Light'), 
        url($s3helv + 'HelveticaNeueUltraLight.otf') format('opentype');
}

@font-face {
    font-family: 'HelveticaNeue';
    font-style: italic;
    font-weight: 100;
    src: local('HelveticaNeueUltraLightItalic'), 
            local('HelveticaNeue-Ultra-Light-Italic'), 
            local('Helvetica Neue Ultra Light Italic'), 
            url($s3helv + 'HelveticaNeueUltraLightItalic.otf') format('opentype');
}

@font-face {
    font-family: 'HelveticaNeue';
    font-style: normal;
    font-weight: 200;
    src: local('HelveticaNeueThin'), 
            local('HelveticaNeue-Thin'), 
            local('Helvetica Neue Thin'), 
            url($s3helv + 'HelveticaNeueThin.otf') format('opentype');
}

@font-face {
    font-family: 'HelveticaNeue';
    font-style: italic;
    font-weight: 200;
    src: local('HelveticaNeueThinItalic'), 
            local('HelveticaNeue-Thin-Italic'), 
            local('Helvetica Neue Thin Italic'), 
            url($s3helv + 'HelveticaNeueThinItalic.otf') format('opentype');
}

@font-face {
    font-family: 'HelveticaNeue';
    font-style: normal;
    font-weight: 300;
    src: local('HelveticaNeueLight'), 
            local('HelveticaNeue-Light'), 
            local('Helvetica Neue Light'), 
            url($s3helv + 'HelveticaNeueLight.otf') format('opentype');
}

@font-face {
    font-family: 'HelveticaNeue';
    font-style: italic;
    font-weight: 300;
    src: local('HelveticaNeueLightItalic'), 
            local('HelveticaNeue-Light-Italic'), 
            local('Helvetica Neue Light Italic'), 
            url($s3helv + 'HelveticaNeueLightItalic.otf') format('opentype');
}

@font-face {
    font-family: 'HelveticaNeue';
    font-style: normal;
    font-weight: 400;
    src:local('Helvetica Neue Roman'),
        local('HelveticaNeue-Roman'), 
        local('HelveticaNeueRoman'), 
        url($s3helv + 'HelveticaNeueRoman.otf') format('opentype');
    descent-override: 0%;
    line-gap-override: 45%;
}

@font-face {
    font-family: 'HelveticaNeue';
    font-style: italic;
    font-weight: 400;
    src: local('Helvetica Neue Roman Italic'),
            local('HelveticaNeue-Roman-Italic'), 
            local('HelveticaNeueRomanItalic'), 
            url($s3helv + 'HelveticaNeueItalic.otf') format('opentype');
}

@font-face {
    font-family: 'HelveticaNeue';
    font-style: normal;
    font-weight: 500;
    src: local('Helvetica Neue Medium'),
            local('HelveticaNeue-Medium'), 
            local('HelveticaNeueMedium'), 
            url($s3helv + 'HelveticaNeueMedium.otf') format('opentype');
}

@font-face {
    font-family: 'HelveticaNeue';
    font-style: italic;
    font-weight: 500;
    src: local('Helvetica Neue Medium Italic'),
            local('HelveticaNeue-Medium-Italic'), 
            local('HelveticaNeueMediumItalic'), 
            url($s3helv + 'HelveticaNeueMediumItalic.otf') format('opentype');
}

@font-face {
    font-family: 'HelveticaNeue';
    font-style: normal;
    font-weight: 700;
    src: local('Helvetica Neue Bold'),
            local('HelveticaNeue-Bold'), 
            local('HelveticaNeueBold'), 
            url($s3helv + 'HelveticaNeueBold.otf') format('opentype');
}

@font-face {
    font-family: 'HelveticaNeue';
    font-style: italic;
    font-weight: 700;
    src: local('Helvetica Neue Bold Italic'),
            local('HelveticaNeue-Bold-Italic'), 
            local('HelveticaNeueBoldItalic'), 
            url($s3helv + 'HelveticaNeueBoldItalic.otf') format('opentype');
}

@font-face {
    font-family: 'HelveticaNeue';
    font-style: normal;
    font-weight: 800;
    src: local('Helvetica Neue Heavy'),
            local('HelveticaNeue-Heavy'), 
            local('HelveticaNeueHeavy'), 
            url($s3helv + 'HelveticaNeueHeavy.otf') format('opentype');
}

@font-face {
    font-family: 'HelveticaNeue';
    font-style: italic;
    font-weight: 800;
    src: local('Helvetica Neue Heavy Italic'),
            local('HelveticaNeue-Heavy-Italic'), 
            local('HelveticaNeueHeavyItalic'), 
            url($s3helv + 'HelveticaNeueHeavyItalic.otf') format('opentype');
}

@font-face {
    font-family: 'HelveticaNeue';
    font-style: normal;
    font-weight: 900;
    src: local('Helvetica Neue Black'),
            local('HelveticaNeue-Black'), 
            local('HelveticaNeueBlack'), 
            url($s3helv + 'HelveticaNeueBlack.otf') format('opentype');
}

@font-face {
    font-family: 'HelveticaNeue';
    font-style: italic;
    font-weight: 900;
    src: local('Helvetica Neue Black Italic'),
            local('HelveticaNeue-Black-Italic'), 
            local('HelveticaNeueBlackItalic'), 
            url($s3helv + 'HelveticaNeueBlackItalic.otf') format('opentype');
}*/
@font-face {
  font-family: "HelveticaNeue";
  font-style: normal;
  font-weight: 100;
  src: url("https://thefloorr-assets.s3.eu-west-2.amazonaws.com/fonts/helvetica-ttf/HelveticaNeueUltraLight.ttf") format("truetype");
}
@font-face {
  font-family: "HelveticaNeue";
  font-style: italic;
  font-weight: 100;
  src: url("https://thefloorr-assets.s3.eu-west-2.amazonaws.com/fonts/helvetica-ttf/HelveticaNeueUltraLightItalic.ttf") format("truetype");
}
@font-face {
  font-family: "HelveticaNeue";
  font-style: normal;
  font-weight: 200;
  src: url("https://thefloorr-assets.s3.eu-west-2.amazonaws.com/fonts/helvetica-ttf/HelveticaNeueThin.ttf") format("truetype");
}
@font-face {
  font-family: "HelveticaNeue";
  font-style: italic;
  font-weight: 200;
  src: url("https://thefloorr-assets.s3.eu-west-2.amazonaws.com/fonts/helvetica-ttf/HelveticaNeueThinItalic.ttf") format("truetype");
}
@font-face {
  font-family: "HelveticaNeue";
  font-style: normal;
  font-weight: 300;
  src: url("https://thefloorr-assets.s3.eu-west-2.amazonaws.com/fonts/helvetica-ttf/HelveticaNeueLight.ttf") format("truetype");
}
@font-face {
  font-family: "HelveticaNeue";
  font-style: italic;
  font-weight: 300;
  src: url("https://thefloorr-assets.s3.eu-west-2.amazonaws.com/fonts/helvetica-ttf/HelveticaNeueLightItalic.ttf") format("truetype");
}
@font-face {
  font-family: "HelveticaNeue";
  font-style: normal;
  font-weight: 400;
  src: url("https://thefloorr-assets.s3.eu-west-2.amazonaws.com/fonts/helvetica-ttf/HelveticaNeue.ttf") format("truetype");
}
@font-face {
  font-family: "HelveticaNeue";
  font-style: italic;
  font-weight: 400;
  src: url("https://thefloorr-assets.s3.eu-west-2.amazonaws.com/fonts/helvetica-ttf/HelveticaNeueItalic.ttf") format("truetype");
}
@font-face {
  font-family: "HelveticaNeue";
  font-style: normal;
  font-weight: 500;
  src: url("https://thefloorr-assets.s3.eu-west-2.amazonaws.com/fonts/helvetica-ttf/HelveticaNeueMedium.ttf") format("truetype");
}
@font-face {
  font-family: "HelveticaNeue";
  font-style: italic;
  font-weight: 500;
  src: url("https://thefloorr-assets.s3.eu-west-2.amazonaws.com/fonts/helvetica5/HelveticaNeueMediumItalic.otf") format("truetype");
}
@font-face {
  font-family: "HelveticaNeue";
  font-style: normal;
  font-weight: 700;
  src: url("https://thefloorr-assets.s3.eu-west-2.amazonaws.com/fonts/helvetica-ttf/HelveticaNeueBold.ttf") format("truetype");
}
@font-face {
  font-family: "HelveticaNeue";
  font-style: italic;
  font-weight: 700;
  src: url("https://thefloorr-assets.s3.eu-west-2.amazonaws.com/fonts/helvetica-ttf/HelveticaNeueBoldItalic.ttf") format("truetype");
}
@font-face {
  font-family: "HelveticaNeue";
  font-style: normal;
  font-weight: 800;
  src: url("https://thefloorr-assets.s3.eu-west-2.amazonaws.com/fonts/helvetica-ttf/HelveticaNeueHeavy.ttf") format("truetype");
}
@font-face {
  font-family: "HelveticaNeue";
  font-style: italic;
  font-weight: 800;
  src: url("https://thefloorr-assets.s3.eu-west-2.amazonaws.com/fonts/helvetica-ttf/HelveticaNeueHeavyItalic.ttf") format("truetype");
}
@font-face {
  font-family: "HelveticaNeue";
  font-style: normal;
  font-weight: 900;
  src: url("https://thefloorr-assets.s3.eu-west-2.amazonaws.com/fonts/helvetica-ttf/HelveticaNeueBlack.ttf") format("truetype");
}
@font-face {
  font-family: "HelveticaNeue";
  font-style: italic;
  font-weight: 900;
  src: url("https://thefloorr-assets.s3.eu-west-2.amazonaws.com/fonts/helvetica-ttf/HelveticaNeueBlackItalic.ttf") format("truetype");
}
@font-face {
  font-family: "Mencken";
  src: local("Mencken"), url("https://thefloorr-assets.s3.eu-west-2.amazonaws.com/fonts/MenckenAppHead-Bold.woff2") format("woff2"), url("https://thefloorr-assets.s3.eu-west-2.amazonaws.com/fonts/MenckenAppHead-Bold.woff") format("woff"), url("https://thefloorr-assets.s3.eu-west-2.amazonaws.com/fonts/MenckenAppHead-Bold.otf") format("opentype");
  font-style: normal;
}
@font-face {
  font-family: "Nobel";
  src: local("Nobel"), url("https://thefloorr-assets.s3.eu-west-2.amazonaws.com/fonts/Nobel-Bold.otf") format("opentype");
  font-style: normal;
  font-weight: 600;
}
@font-face {
  font-family: "Nobel";
  src: local("Nobel"), url("https://thefloorr-assets.s3.eu-west-2.amazonaws.com/fonts/Nobel-Book.otf") format("opentype");
  font-style: normal;
  font-weight: 400;
}
@font-face {
  font-family: "Nobel";
  src: local("Nobel"), url("https://thefloorr-assets.s3.eu-west-2.amazonaws.com/fonts/Nobel-Regular.otf") format("opentype");
  font-style: normal;
  font-weight: 500;
}
@font-face {
  font-family: "Nobel";
  src: local("Nobel"), url("https://thefloorr-assets.s3.eu-west-2.amazonaws.com/fonts/Nobel-Light.otf") format("opentype");
  font-style: normal;
  font-weight: 300;
}
h2, h2.ant-typography,
h3, h3.ant-typography,
h4, h4.ant-typography,
h5, h5.ant-typography {
  font-family: HelveticaNeue;
}

h1, h1.ant-typography {
  font-family: Mencken;
  font-size: 2.375rem;
  line-height: 2.875rem;
  font-weight: 700;
}

h3, h3.ant-typography {
  font-size: 0.875rem;
  line-height: 1.375rem;
}

p {
  line-height: 1.375rem;
  font-size: 0.875rem;
}

a {
  color: #1d1d1d;
}
.icon {
  pointer-events: none;
}

.icon-svg-fill {
  fill: currentColor;
}

.icon-svg-stroke {
  fill: none;
  stroke: currentColor;
}
html, body {
  background-color: #ffffff;
  font-family: HelveticaNeue;
}

html.html-dark-bg {
  background-color: #141414;
}
html.html-dark-bg body {
  background-color: #141414;
}

body.no-scroll {
  overflow: hidden;
}

#root {
  min-height: 100vh;
  min-height: 100dvh;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-wrap: nowrap;
}

.ant-app {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-wrap: nowrap;
}

.app-loading {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.app-view {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: stretch;
}

html.html-100h #root {
  height: 100vh;
  max-height: 100vh;
}
html.html-100h .app-view {
  height: 100%;
}
.ant-avatar {
  line-height: 1.875rem;
  font-size: 0.875rem;
  aspect-ratio: 1;
}

.ant-avatar.ant-avatar-sm {
  line-height: 1.35rem;
}
.ant-badge .ant-badge-count {
  background-color: #1d1d1d;
}
.ant-badge:hover .ant-badge-count {
  background-color: #8c8c8c;
}
a:hover .ant-badge .ant-badge-count {
  background-color: #8c8c8c;
}
/*
.ant-btn {
    .ant-icon {
        vertical-align: text-top;
    }
    .icon {
        width: 1rem;
        height: 1rem;
    }

}

.ant-btn-primary {
    .icon {
        color: $color-background;
    }

    &:disabled {
        border-color: #1d1d1dcc;
        background-color: #1d1d1dcc;
        color: #ffffffaa;
        .icon {
            color: #ffffffaa;
        }
    }
}

.ant-btn-text {
    font-weight: 400;   
}

.ant-btn-text:not(:disabled):not(.ant-btn-disabled) {
    &:hover {
        background: transparent;
    }
}
.ant-btn-text:disabled, .ant-btn-text.ant-btn-disabled {
    background: transparent;
}

.ant-btn-link {
    span {
        text-decoration: underline;
        text-underline-offset: 2px;
        text-decoration-thickness: 1px;
    }
}

.ant-btn.ant-btn-icon {
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
    background-color: #fff;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    .icon {
        color: $color-primary;
        width: 1rem;
        height: 1rem;
    }

    &:hover {
        background-color: $color-light;
    }
}

.ant-btn-sm .ant-btn-icon svg {
    width: 0.75rem;
    height: 0.75rem;
}


.ant-btn-sm.ant-btn-circle.ant-btn-icon-only {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 1.5rem;
    width: 1.5rem;
    height: 1.5rem;
}
*/
.ant-input-search-button {
  padding: 0 !important;
  height: auto !important;
  border: 0 !important;
  background-color: transparent !important;
}
.ant-input-search-button:hover {
  background-color: transparent !important;
}
.ant-input-search-button .button {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}
.tf-drawer .ant-drawer-header-title {
  flex-direction: row-reverse;
}
.tf-drawer .ant-drawer-close {
  margin-right: 0;
  -webkit-margin-end: 0;
          margin-inline-end: 0;
}
.tf-drawer .ant-drawer-title {
  text-align: center;
  font-weight: 300;
  text-transform: uppercase;
}
.ant-dropdown .active {
  font-weight: 600;
}

.ant-dropdown-menu-item svg {
  width: 1.2rem;
  height: 1.2rem;
}

.ant-dropdown-menu {
  padding: 0 !important;
}

.ant-dropdown .ant-dropdown-menu-item {
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  height: 3rem;
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}
.ant-dropdown .ant-dropdown-menu-item:last-child {
  border-bottom: 0;
}
.ant-image .loading {
  display: block;
  width: 3rem;
  height: 3rem;
  color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.ant-image .loading .icon {
  width: 3rem;
  height: 3rem;
}

.ant-image-preview-content .ant-image {
  max-height: 100vh;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ant-image-preview-content .ant-image .ant-image-img {
  object-fit: contain;
  object-position: center;
  display: block;
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
}
.ant-input {
  font-size: 0.75rem;
  line-height: 1rem;
  height: 1.75rem;
}

.ant-input-sm {
  font-size: 0.75rem;
  line-height: 1rem;
  height: 1.5rem;
}

.ant-input-lg {
  font-size: 0.875rem;
  line-height: 1rem;
  height: 2.25rem;
}

.ant-input-affix-wrapper {
  padding-top: 0;
  padding-bottom: 0;
}
.ant-input-affix-wrapper .ant-input {
  font-size: 0.75rem !important;
  line-height: 1rem;
  height: calc(1.75rem - 2px);
}
.ant-input-affix-wrapper .ant-input-sm {
  font-size: 0.75rem !important;
  line-height: 1rem;
  height: calc(1.5rem - 2px);
}
.ant-input-affix-wrapper .ant-input-lg {
  font-size: 0.875rem !important;
  line-height: 1rem;
  height: calc(2.25rem - 2px);
}
.ant-input-affix-wrapper .ant-input-lg::-webkit-input-placeholder {
  font-size: 0.875rem !important;
  line-height: 1rem;
}
.ant-input-affix-wrapper .ant-input-lg::placeholder {
  font-size: 0.875rem !important;
  line-height: 1rem;
}
.ant-input-affix-wrapper .ant-input-prefix .icon {
  color: rgba(0, 0, 0, 0.25);
  transition: color 0.2s;
}

.ant-input-affix-wrapper-focused .ant-input-prefix .icon {
  color: #1d1d1d;
}

.ant-input-affix-wrapper-sm::before {
  line-height: 1.375rem !important;
}

.ant-input-affix-wrapper-lg .ant-input-prefix .icon {
  width: 0.875rem;
  height: 0.875rem;
}

.ant-input-wrapper.ant-input-group {
  font-size: 0;
}

.ant-checkbox .ant-checkbox-inner {
  border-radius: 0;
}

.ant-input-password-icon > svg {
  height: 14px;
  width: 14px;
}
.ant-modal .ant-modal-content {
  border-radius: 0;
  padding: 1rem;
}
.ant-modal .ant-modal-close:hover {
  background-color: transparent;
}
.ant-modal .ant-modal-close-x {
  font-size: 1.5rem;
}
.ant-modal > div:nth-child(2) {
  height: 100%;
}

.ant-modal-info .ant-modal-info-body {
  margin-bottom: 1.5rem;
  margin-top: 1.5rem;
}
.ant-modal-info .ant-modal-info-title {
  font-weight: 700;
  font-family: Mencken;
  font-size: 1.5rem;
  margin: 0.5rem 0;
}
.ant-modal-info .ant-modal-info-content {
  font-size: 0.875rem;
  max-width: 60%;
  margin: auto;
  font-weight: 300;
  margin-bottom: 0.5rem;
}
.ant-modal-info .ant-modal-info-paragraph {
  text-align: center;
  max-width: 100%;
}
.ant-modal-info .ant-modal-info-btns {
  display: flex;
  justify-content: center;
}

.ant-modal-confirm .ant-modal-confirm-body {
  margin-bottom: 1.5rem;
  margin-top: 1.5rem;
}
.ant-modal-confirm .ant-modal-confirm-title {
  font-weight: 700;
  font-family: Mencken;
  font-size: 1.5rem;
  margin: 0.5rem 0;
}
.ant-modal-confirm .ant-modal-confirm-content {
  font-size: 0.875rem;
  max-width: 60%;
  margin: auto;
  font-weight: 300;
  margin-bottom: 0.5rem;
}
.ant-modal-confirm .ant-modal-confirm-paragraph {
  text-align: center;
  max-width: 100%;
}
.ant-modal-confirm .ant-modal-confirm-btns {
  display: flex;
  justify-content: center;
}

.modal-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  text-align: center;
}
.modal-header h2 {
  font-family: Mencken;
  font-size: 1.5rem;
  font-weight: 700;
}
.modal-header p {
  font-size: 1rem;
  font-weight: 400;
}
.ant-select {
  text-transform: none;
}

.ant-select-arrow .icon-select-arrow {
  color: #bfbfbf;
  width: 0.5rem;
  height: 0.5rem;
  transition: color 0.4s;
}
.ant-select-arrow .icon-expand {
  color: #1d1d1d;
  width: 0.875rem;
  height: 0.875rem;
  transition: color 0.4s;
}

.ant-select:hover .ant-select-arrow .icon, .ant-select.ant-select-open .ant-select-arrow .icon {
  color: #1d1d1d;
}

.ant-select input {
  font-size: 0.75rem;
}
.ant-select .ant-select-selection-placeholder {
  font-size: 0.75rem;
}

.ant-select-sm input {
  font-size: 0.875rem;
}
.ant-select-sm .ant-select-selection-placeholder {
  font-size: 0.875rem;
}
.ant-select-sm .ant-select-selection-item {
  font-size: 0.875rem;
}

.ant-select-lg input {
  font-size: 0.875rem;
}
.ant-select-lg .ant-select-selection-placeholder {
  font-size: 0.875rem;
}
.ant-select-lg .ant-select-selection-item {
  font-size: 0.875rem;
}
.ant-table .ant-table-thead .ant-table-cell {
  font-weight: 500;
  font-size: 0.875rem;
}
.ant-table .ant-table-tbody .ant-table-cell {
  font-weight: 400;
  font-size: 0.875rem;
}

.table-cell-nowrap {
  white-space: nowrap;
}
.actions-toolbar {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: flex-start;
  justify-content: center;
}
.actions-toolbar .button--circle.button--icon-w-text {
  width: 66px;
}
.actions-toolbar .button--secondary.button--circle.button--icon-w-text, .actions-toolbar .button--primary.button--circle.button--icon-w-text {
  width: 66px;
}
.catalogue-back-to-top.modal {
  position: absolute;
  right: 0;
}
.catalogue-back-to-top.body {
  position: fixed;
  bottom: 5.5rem;
  right: 5.5rem;
}
.catalogue-back-to-top.body span.button__icon {
  width: 3rem;
  height: 3rem;
}
.catalogue-back-to-top.body span.button__icon .button__icon-svg--large {
  width: 1.5rem;
  height: 1.5rem;
}
.catalogue-back-to-top.body .button__text {
  font-size: 0.75rem;
  font-family: Nobel;
  font-weight: 400;
}
.card-radio-select {
  width: 1rem;
  height: 1rem;
  border-radius: 0.5rem;
  border: 1px solid #1d1d1d;
  display: flex;
  align-items: center;
  justify-content: center;
}
.card-radio-select:before {
  content: "";
  display: block;
  width: 0.7rem;
  height: 0.7rem;
  border-radius: 0.35rem;
  background-color: transparent;
  transition: background-color 0.2s;
}
.card-radio-select.active:before {
  background-color: #1d1d1d;
}
.chat {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: stretch;
  height: calc(100% - 125px);
}

.chat-contacts {
  background-color: #f3f0eb;
  width: 21rem;
  min-width: 21rem;
  border-right: 1px solid rgba(0, 0, 0, 0.06);
  overflow: auto;
}

.chat-contacts-inner {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: 1.5rem;
}

.chat-contacts-invite {
  width: 100%;
  display: flex;
}
.chat-contacts-invite .button {
  flex: 1;
}

.chat-contacts-contact {
  display: flex;
  flex-direction: row;
  cursor: pointer;
  background-color: #ffffff;
  border-radius: 0.5rem;
  padding: 1rem;
  gap: 0.5rem;
  align-items: center;
  box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.16);
  border-width: 1px;
  border-style: solid;
  border-color: #ffffff;
  transition: border-color 0.2s;
  position: relative;
}
.chat-contacts-contact .icon {
  margin-left: auto;
  min-width: 1rem;
  width: 1rem;
  height: 1rem;
}
.chat-contacts-contact .ant-avatar {
  min-width: 2rem;
}
.chat-contacts-contact .ant-badge {
  position: absolute;
  top: 0.25rem;
  right: 0.25rem;
}
.chat-contacts-contact.active {
  border-color: #1d1d1d;
}

.chat-contacts-contact-name {
  font-weight: 500;
  font-size: 0.75rem;
}

.chat-contacts-contact-status {
  font-weight: 300;
  font-size: 0.75rem;
}

.chat-consultations {
  background-color: #f3f0eb;
  width: 18rem;
  min-width: 18rem;
  border-right: 1px solid rgba(0, 0, 0, 0.06);
  overflow: auto;
}

.chat-consultations-inner {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  padding: 1.5rem;
}

.chat-consultations-consultation {
  cursor: pointer;
  background-color: #ffffff;
  border-radius: 0.5rem;
  padding: 1rem;
  box-shadow: 1px 1px 8px 0px rgba(0, 0, 0, 0.08);
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 244px;
}
.chat-consultations-consultation h3 {
  font-size: 1rem;
  font-weight: 500;
}
.chat-consultations-consultation p {
  font-size: 0.875rem;
  font-weight: 300;
}

.chat-consultations-consultation-look {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  align-items: stretch;
}

.chat-consultations-consultation-look-images {
  flex: 1;
  display: flex;
  flex-direction: row;
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 0.5rem;
  gap: 0.25rem;
  padding: 0.125rem;
  overflow: hidden;
}
.chat-consultations-consultation-look-images div {
  height: 5rem;
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 0.5rem;
  aspect-ratio: 0.58;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.chat-main {
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.chat-with-contacts .chat-main {
  width: calc(100% - 21rem);
}

.chat-with-consultations .chat-main {
  width: calc(100% - 18rem);
}

.chat-with-contacts.chat-with-consultations .chat-main {
  width: calc(100% - 18rem - 21rem);
}

.chat-main-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0.688rem 1rem;
  gap: 0.5rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.015);
  z-index: 1;
}

.chat-main-header-user-info {
  display: flex;
  flex-direction: column;
}

.chat-main-header-user-info-name {
  font-size: 1rem;
  font-weight: 500;
}

.chat-main-header-user-info-status {
  font-size: 0.875rem;
  font-weight: 300;
}

.chat-form {
  display: flex;
  flex-direction: column;
}

.chat-form-input {
  padding: 24px;
}

textarea.chat-form-input-field {
  resize: none;
  padding-top: 7px;
  height: 150px;
}

.chat-form-input-broadcast {
  width: 25%;
  margin-right: auto;
}

.chat-form-input-audio {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
}
.chat-form-input-audio a {
  font-size: 0;
}
.chat-form-input-audio .icon {
  width: 1rem;
  height: 1rem;
}
.chat-form-input-audio audio {
  height: 1.5rem;
  width: 15rem;
  max-width: 15rem;
}

.chat-form-input-group {
  width: 100%;
  display: flex;
  gap: 1rem;
}
.chat-form-input-group-broadcast {
  flex-wrap: wrap;
}

.chat-form-input-button {
  background: none;
  box-shadow: none;
  border: none;
  color: #1d1d1d;
  padding: 0 !important;
}
.chat-form-input-button svg {
  width: 1.5rem;
  height: 1.5rem;
}
.chat-form-input-button.button--disabled {
  opacity: 0.4;
}

.dropzone {
  z-index: 100;
  display: flex;
  gap: 8px;
}
.dropzone .dropzone-label {
  font-size: 16px;
}
.dropzone input {
  height: 20px;
  -webkit-transform: translateX(-50%) translateY(-30%);
          transform: translateX(-50%) translateY(-30%);
}

.chat-form-input-actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.625rem;
  color: #4d4d4d;
}
.chat-form-input-actions a {
  color: #4d4d4d;
  transition: color 0.2s;
  font-size: 0;
}
.chat-form-input-actions a:hover {
  color: #1d1d1d;
}
.chat-form-input-actions .icon {
  width: 1rem;
  height: 1rem;
}

.chat-form-attachments {
  flex: 1;
  width: inherit;
  overflow: auto;
  border-top: 1px solid rgba(0, 0, 0, 0.06);
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  scroll-behavior: smooth;
  background-color: #f0f0f0;
  padding: 1rem 1.5rem;
}

.chat-form-attachments-list {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  width: -webkit-min-content;
  width: min-content;
}

.chat-form-attachment {
  border-radius: 0.5rem;
  padding: 0.25rem;
  box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.16);
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  gap: 0.25rem;
  background-color: #fff;
}
.chat-form-attachment .catalogue-product-card {
  width: 68px;
}
.chat-form-attachment .moodboard-card {
  box-shadow: none;
  padding: 0;
}
.chat-form-attachment .consultation-card,
.chat-form-attachment .look-card,
.chat-form-attachment .look-card-tagged {
  width: 136px;
  border-radius: 0.5rem;
}
.chat-form-attachment .consultation-card__image,
.chat-form-attachment .look-card__image,
.chat-form-attachment .look-card-tagged__image {
  aspect-ratio: 128/176;
}
.chat-form-attachment .consultation-card-image,
.chat-form-attachment .look-card-image,
.chat-form-attachment .look-card-tagged-image {
  border-radius: 0.5rem;
}

.chat-form-attachment-image {
  aspect-ratio: 68/93;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 68px;
  min-height: 68px;
  flex: 1;
  border-radius: 0.5rem;
}

.chat-form-attachment-look {
  display: flex;
  flex-direction: column;
}
.chat-form-attachment-look h4 {
  text-transform: uppercase;
  font-size: 0.875rem;
  margin-top: 0.5rem;
}

.chat-form-attachment-look-images {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
}

.chat-form-attachment-look-image {
  aspect-ratio: 0.58;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 68px;
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 0.5rem;
}

.chat-form-attachment-product {
  aspect-ratio: 0.58;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 68px;
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 0.5rem;
}

.chat-form-attachment-remove {
  width: 0.5rem;
  height: 0.5rem;
}
.chat-form-attachment-remove .icon {
  width: 0.5rem;
  margin-bottom: 0.35rem;
  height: 0.5rem;
  margin-right: 0.25rem;
}

.chat-form-reply {
  border-top: 1px solid rgba(0, 0, 0, 0.06);
  padding: 0.5rem 0;
  position: relative;
}
.chat-form-reply .chat-message {
  padding: 0;
  flex-direction: row;
  margin-left: 0;
  margin-right: 0;
  opacity: 0.5;
}
.chat-form-reply .chat-message .look-card,
.chat-form-reply .chat-message .look-card-tagged {
  min-width: 156px;
}
.chat-form-reply > .close {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
}
.chat-form-reply > .close .icon {
  width: 1rem;
  height: 1rem;
}

.audio-recorder {
  display: flex;
  align-items: center;
  width: 100%;
}
.audio-recorder-actions {
  display: flex;
  gap: 1.5rem;
  flex-shrink: 0;
}
.audio-recorder-time {
  font-size: 12px;
  font-weight: 300;
  margin: 0 1rem 0 1.5rem;
}
.audio-recorder > .chat-form-input-button {
  margin-left: 1.5rem;
  flex-shrink: 0;
}
.audio-recorder > .chat-form-input-button:disabled {
  opacity: 0.5;
}
.audio-recorder > .chat-form-input-button.button--disabled {
  opacity: 0.5;
}
.audio-recorder .ant-progress-bg {
  background-color: #434343;
}

.chat-audio-recorder .ant-modal {
  width: 309px;
}
.chat-audio-recorder .chat-audio-recorder-status {
  text-align: center;
  font-weight: 500;
  font-size: 0.875rem;
}
.chat-audio-recorder .chat-audio-recorder-icon .icon {
  width: 2rem;
  height: 2rem;
}
.chat-audio-recorder .ant-modal-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
  padding-top: 2rem;
}
.chat-audio-recorder .chat-audio-recorder-icon {
  position: relative;
  width: 4rem;
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.chat-audio-recorder .chat-audio-recorder-icon:before, .chat-audio-recorder .chat-audio-recorder-icon:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #c71212;
  border-radius: 50%;
  opacity: 0;
  transition: opacity 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.chat-audio-recorder.recording .chat-audio-recorder-icon {
  color: #c71212;
}
.chat-audio-recorder.recording .chat-audio-recorder-icon:before, .chat-audio-recorder.recording .chat-audio-recorder-icon:after {
  opacity: 0.5;
  -webkit-animation: animate 2s linear infinite;
          animation: animate 2s linear infinite;
}
.chat-audio-recorder.recording .chat-audio-recorder-icon:after {
  -webkit-animation-delay: 1s;
          animation-delay: 1s;
}
@-webkit-keyframes animate {
  0% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    opacity: 0.1;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
}
@keyframes animate {
  0% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    opacity: 0.1;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
}
@-webkit-keyframes coloring {
  0% {
    color: #bfbfbf;
  }
  50% {
    color: #c71212;
  }
  100% {
    color: #bfbfbf;
  }
}
@keyframes coloring {
  0% {
    color: #bfbfbf;
  }
  50% {
    color: #c71212;
  }
  100% {
    color: #bfbfbf;
  }
}
.chat-audio-recorder.loading .chat-audio-recorder-icon .icon {
  -webkit-animation: coloring 2s linear infinite;
          animation: coloring 2s linear infinite;
}

.chat-messages {
  flex: 1;
  overflow: auto;
  position: relative;
  display: flex;
  flex-direction: column-reverse;
  background-color: #f0f0f0;
  transition: background-color 0.2s;
}
.chat-messages:has(.chat-message-with-reactions) {
  background-color: #f0f0f0;
}

.chat-messages-loading-previous {
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.chat-messages-loading {
  position: absolute;
  display: block;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.chat-message {
  display: flex;
  gap: 0.5rem;
  align-items: flex-end;
  padding: 0.75rem;
  margin-left: 0.75rem;
  margin-right: 0.75rem;
  position: relative;
}
.chat-message .ant-avatar {
  display: block;
  min-width: 1.5rem;
}
.chat-message .icon-tick {
  width: 0.75rem;
  height: 0.75rem;
  color: #bfbfbf;
  -webkit-transform: translateY(25%);
          transform: translateY(25%);
}
.chat-message .icon-tick-double {
  width: 0.875rem;
  height: 0.875rem;
  color: #40a9ff;
  -webkit-transform: translateY(25%);
          transform: translateY(25%);
}
.chat-message .loading {
  align-self: center;
  width: 20px;
  height: 20px;
}
.chat-message .emoji-picker {
  position: absolute;
  bottom: 2rem;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.chat-message.chat-message-with-reactions {
  background-color: #ffffff;
  border-radius: 0.5rem;
}
.chat-message .look-card,
.chat-message .look-card-tagged {
  min-width: 156px;
}

.chat-message-text {
  border-top: 0.5px solid #d9d9d9;
  padding: 0.25rem 0;
  word-wrap: break-word;
  font-weight: 300;
}
.chat-message-text a {
  text-decoration: underline;
}
.chat-message-text.plain-text {
  border-top: none;
}

.chat-message-content {
  padding: 0.25rem;
  background-color: #ffffff;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  position: relative;
  max-width: 70%;
  box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.16);
}
.chat-message-content .consultation-card__image,
.chat-message-content .look-card-tagged-image {
  aspect-ratio: 190/260;
}
.chat-message-content .look-card-tagged-image {
  border-radius: 8px;
}

.chat-message-content-plain {
  padding: 0.25rem 0.5rem;
}

.chat-message-reactions {
  position: absolute;
  bottom: 0;
  -webkit-transform: translateY(50%);
          transform: translateY(50%);
  display: flex;
  flex-direction: row;
}

.chat-message-reactions-ofmine {
  cursor: pointer;
}

.chat-message-info {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: flex-end;
  display: none;
  color: #4d4d4d;
}
.chat-message-info .icon-reply,
.chat-message-info .icon-reaction-add,
.chat-message-info .icon-delete {
  width: 0.875rem;
  height: 0.875rem;
  -webkit-transform: translateY(15%);
          transform: translateY(15%);
}
.chat-message:hover .chat-message-info {
  display: flex;
}
.chat-message-info .chat-message-info-delete {
  cursor: pointer;
}

.chat-message-info-date {
  font-size: 0.75rem;
  color: #4d4d4d;
}

.chat-message-mine {
  flex-direction: row-reverse;
}
.chat-message-mine .chat-message-reactions {
  right: 0;
}
.chat-message-mine audio::-webkit-media-controls-panel {
  background-color: #f3f0eb;
}

.chat-message-other {
  flex-direction: row;
}
.chat-message-other .chat-message-info {
  flex-direction: row-reverse;
}
.chat-message-other .chat-message-reactions {
  left: 0;
}
.chat-message-other audio::-webkit-media-controls-panel {
  background-color: #f0f0f0;
}

.chat-message-catalogue-products {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  background-color: #ffffff;
  max-width: 400px;
  overflow: auto;
}
.chat-message-catalogue-products .catalogue-product-card {
  min-width: 156px;
}
.chat-message-catalogue-products .catalogue-product-card-image {
  border-radius: 0.5rem;
}

.chat-message-content-time {
  font-size: 0.5rem;
  font-weight: 300;
  text-align: right;
}

.chat-message-images {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  background-color: #ffffff;
  max-width: 400px;
  overflow: auto;
}

.chat-message-image {
  aspect-ratio: 128/175;
  min-width: 156px;
  background-position: center;
  cursor: pointer;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: lightgray 50%/cover no-repeat;
}
.chat-message-image.uploading {
  cursor: default;
}

.chat-message-voicemessage {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 0 8px;
  position: relative;
}
.chat-message-voicemessage .button {
  width: 14px;
  height: 14px;
}
.chat-message-voicemessage .button svg {
  width: 14px;
  height: 14px;
}
.chat-message-voicemessage audio {
  display: none;
}
.chat-message-voicemessage-duration {
  font-size: 14px;
  font-weight: 300;
}
.chat-message-voicemessage .ant-progress {
  width: 155px;
}
.chat-message-voicemessage .ant-slider {
  width: 155px;
  margin: 0;
}

.chat-message-voicemessage-dropdown .ant-dropdown-menu-item,
.chat-message-voicemessage-dropdown .ant-dropdown-menu-submenu {
  height: 2.5rem !important;
}
.chat-message-voicemessage-dropdown .ant-dropdown-menu-submenu {
  display: flex;
  align-items: center;
}

.ant-dropdown-menu-submenu .ant-dropdown-menu .ant-dropdown-menu-item {
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  height: 2rem;
}

.ant-dropdown .ant-slider {
  width: 100px;
  margin: 0;
}

.chat-message-look {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}
.chat-message-look .look-card,
.chat-message-look .look-card-tagged {
  cursor: pointer;
  width: 244px;
  background-color: #fff;
  border-radius: 0.5rem;
}
.chat-message-look > p {
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
}
.chat-message-look-href {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
}

.chat-message-uploading {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  font-style: italic;
  align-items: center;
  color: #4d4d4d;
}

.chat-message-reply .chat-message {
  opacity: 0.5;
  padding: 0;
  margin: 0;
  flex-direction: row;
}
.chat-message-reply .chat-message .chat-message-content {
  padding: 0;
  max-width: 100%;
}
.chat-message-reply .chat-message .chat-message-look .look-card,
.chat-message-reply .chat-message .chat-message-look .look-card-tagged {
  min-width: 156px;
}

.chat-moodboard-card-select {
  display: block;
  position: absolute;
  aspect-ratio: 0.58;
  width: 100%;
  top: 0;
  left: 0;
}
.chat-moodboard-card-select .input-radio {
  position: absolute;
  top: 0.75rem;
  left: 1rem;
}
.chat-moodboard-card-select .card-radio-select {
  position: absolute;
  top: 0.75rem;
  left: 1rem;
}

.invite-link-wrapper {
  display: flex;
  gap: 0.5rem;
  margin-top: 2rem;
  width: 350px;
}
.invite-link-wrapper .ant-input {
  height: 36px;
}
.invite-link-wrapper .button {
  min-width: -webkit-min-content;
  min-width: min-content;
}
.invite-link-wrapper .button span svg {
  height: 12px;
  width: 12px;
  margin-left: 0.25rem;
}

.modal-invite .ant-modal-content .ant-modal-body .ant-modal-confirm-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.consultation-status-select .ant-select-selection-item {
  text-decoration: underline;
}
.context-menu {
  min-width: 15rem !important;
}
.context-menu .ant-dropdown-menu-title-content {
  font-size: 14px;
}

.context-menu-item-multiline {
  display: flex;
  flex-direction: column;
}
.context-menu-item-multiline span {
  font-size: 12px;
  font-weight: 300;
}
.context-menu-item-multiline p {
  line-height: 1;
}
.customer-sizes {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
}
.customer-sizes .icon {
  width: 1rem;
  height: 1rem;
}
.customer-sizes span {
  font-size: 0.875rem;
  font-weight: 300;
}
.customer-sizes > div {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.25rem;
}
.dropzone {
  position: relative;
  cursor: pointer;
  font-size: 0;
  overflow: hidden;
}
.dropzone input {
  -webkit-transform: translate(-20%, -10px);
          transform: translate(-20%, -10px);
  opacity: 0;
  position: absolute;
  cursor: pointer;
  width: 100px;
  height: 100px;
}
.dropzone input::-webkit-file-upload-button { /* chromes and blink button */
  cursor: pointer;
}
.dropzone p {
  cursor: pointer;
  text-align: center;
  width: 60%;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.dropzone.drag-active {
  background-color: rgba(191, 191, 191, 0.3);
}
.emoji-picker {
  min-width: 400px;
  max-width: 480px;
  height: 2rem;
  background-color: #ffffff;
  box-shadow: 1px 1px 8px 0px rgba(0, 0, 0, 0.08);
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  transition: height 0.3s;
  z-index: 10;
}
.emoji-picker.expanded {
  height: 400px;
}

.emoji-picker-emojis {
  display: flex;
  flex-wrap: wrap;
  overflow: auto;
  flex: 1;
  align-content: flex-start;
}

.emoji-picker-emoji {
  width: 2rem;
  height: 2rem;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.5rem;
}
.emoji-picker-emoji:hover {
  background-color: #f0f0f0;
}

.emoji-picker-groups {
  height: 2rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  display: flex;
}

.emoji-picker-group {
  display: block;
  width: 2rem;
  height: 2rem;
  font-size: 1.5rem;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
}
.emoji-picker-group:hover {
  background-color: #f0f0f0;
}
.emoji-picker-group.current {
  -webkit-filter: none;
  filter: none;
}

.emoji-picker-recents {
  height: 2rem;
  display: flex;
}
.emoji-picker.expanded .emoji-picker-recents {
  border-bottom: 1px solid #bfbfbf;
}

.emoji-recents-expand {
  width: 2rem;
  height: 2rem;
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.emoji-recents-expand .icon {
  width: 1rem;
  height: 1rem;
  color: #4d4d4d;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
}
.emoji-recents-expand:last-child {
  margin-right: 0.25rem;
}

.emoji-picker-delete {
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 0.25rem;
}
.emoji-picker-delete:hover {
  background-color: #f0f0f0;
}
.emoji-picker-delete .icon {
  width: 0.875rem;
  height: 0.875rem;
  color: #c71212;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
}

.emoji-recents-close {
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.emoji-recents-close .icon {
  width: 0.875rem;
  height: 0.875rem;
  color: #4d4d4d;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
}
.emoji-recents-close:last-child {
  margin-right: 0.25rem;
}

.emoji-picker.expanded .emoji-recents-expand .icon {
  -webkit-transform: rotate(-180deg);
          transform: rotate(-180deg);
}
.empty-message {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
}
.empty-message--small {
  margin: auto;
}

.empty-message__message {
  font-size: 1.25rem;
}
.empty-message__message--small {
  font-size: 1rem;
  font-weight: 300;
}
.expandable-filter-head {
  display: flex;
  justify-content: space-between;
  gap: 0.5rem;
  align-items: center;
  min-height: 3.375rem;
  cursor: pointer;
  border-bottom: 1px solid #d9d9d9;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.expandable-filter-head-main {
  display: flex;
  flex-direction: column;
  margin-right: auto;
}

.expandable-filter-head-title {
  font-size: 0.75rem;
  line-height: 1rem;
  letter-spacing: 0.5px;
  font-weight: 500;
  text-transform: uppercase;
}

.expandable-filter-head-subtitle {
  font-size: 0.75rem;
  line-height: 1rem;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #444;
}

.expandable-filter-head-clear {
  display: block;
  width: 0.65rem;
  min-width: 0.65rem;
  height: 0.65rem;
  line-height: 0;
  -webkit-transform: rotate(0);
          transform: rotate(0);
  -webkit-transform-origin: 50% 50%;
          transform-origin: 50% 50%;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
}
.expandable-filter-head-clear .icon {
  width: 0.65rem;
  height: 0.65rem;
}

.expandable-filter-head-toggle {
  display: block;
  min-width: 0.875rem;
  width: 0.875rem;
  height: 0.875rem;
  line-height: 0;
  -webkit-transform: rotate(0);
          transform: rotate(0);
  -webkit-transform-origin: 50% 50%;
          transform-origin: 50% 50%;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
}
.expandable-filter-head-toggle .icon {
  width: 0.875rem;
  height: 0.875rem;
}

.expandable-filter-head-expanded .expandable-filter-head-toggle {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.expandable-filter-body {
  max-height: 15rem;
  overflow: auto;
  padding: 0.5rem 0;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  border-bottom: 1px solid #d9d9d9;
}

.expandable-filter-search {
  padding: 0 0.5rem;
}

.expandable-filter-item {
  padding: 0.25rem 0.5rem;
}
.expandable-filter-item .input-radio {
  display: flex;
  justify-content: flex-start;
  gap: 0.5rem;
  width: 100%;
  align-items: center;
  line-height: 1;
}
.expandable-filter-item .input-checkbox {
  line-height: 1;
  width: 100%;
}
.expandable-filter-item .input-radio__label {
  text-align: left;
  font-size: 0.875rem;
  flex: 1;
}
.expandable-filter-item .input-checkbox__label {
  text-align: left;
  font-size: 0.875rem;
  flex: 1;
}

.expandable-filter-loading {
  display: flex;
  justify-content: center;
  align-items: center;
}

.expandable-filter-badge {
  border: 1px solid #1d1d1d;
  border-radius: 0.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.75rem;
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  flex-wrap: wrap;
  cursor: default;
  background-color: #fff;
}
.expandable {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.expandable.enabled {
  cursor: pointer;
}
.expandable.expanded {
  -webkit-line-clamp: unset;
}
.footer {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  height: 116px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}
.footer .logo {
  width: 150px;
  height: 16px;
  color: #1d1d1d;
}
.footer p {
  color: #8c8c8c;
  font-size: 0.75rem;
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: rgba(0, 0, 0, 0.06);
  width: 100%;
  text-align: right;
  margin-top: 1rem;
  padding-top: 1rem;
}

html.html-dark-bg .footer .logo {
  color: #fff;
}
html.html-dark-bg .footer p {
  color: #fff;
  border-top-color: #fff;
}

html.html-hide-footer .footer {
  display: none;
}
.header {
  background-color: #ffffff;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-top: 1rem;
  height: 125px;
}
.header .logo {
  width: 223px;
  height: 23px;
}

.header-top {
  height: 2.25rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.header-top .header-username {
  text-transform: uppercase;
  display: flex;
  align-items: center;
  gap: 1.5rem;
  font-weight: 700;
  letter-spacing: 0.32px;
  font-size: 0.875rem;
}
.header-top .header-username.active {
  font-weight: 500;
}

.header-top-right {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 1.5rem;
}

.header-menu {
  margin-top: 1rem;
  height: 57px;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  gap: 2rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
}

.header-menu-link {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-transform: uppercase;
  position: relative;
  letter-spacing: 0.32px;
  font-weight: 300;
  font-size: 1rem;
  cursor: pointer;
}
.header-menu-link::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 2px;
  background-color: transparent;
  transition: background-color 0.2s;
}
.header-menu-link:hover::before {
  background-color: #8c8c8c;
}
.header-menu-link.active::before {
  background-color: #1d1d1d;
}

.dropdown-nav-link.active {
  font-weight: 500;
}

.header-currency-selector {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
  font-size: 0.875rem;
  height: 2rem;
  text-transform: uppercase;
}
.header-currency-selector .icon {
  width: 0.875rem;
  height: 0.875rem;
}

.ant-dropdown.header-currency-dropdown .ant-dropdown-menu-item {
  font-size: 0.875rem;
}

.header-notifications-trigger {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
  height: 2rem;
}
.header-notifications-trigger .icon {
  width: 1.5rem;
  height: 1.5rem;
}

.header-notifications-popover .ant-popover-inner {
  padding: 0;
}

html.html-hide-header .header {
  display: none;
}
.image-slider-indicator {
  height: 4px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: row;
  gap: 2px;
}

.image-slider-indicator-dot {
  width: 4px;
  height: 4px;
  background-color: #bfbfbf;
  transition: background-color 0.2s;
  border-radius: 2px;
}
.image-slider-indicator-dot.active {
  background-color: #4d4d4d;
}
.image-slider {
  position: relative;
}

.image-slider-scroller {
  width: 100%;
  height: 100%;
  scroll-snap-type: x mandatory;
  overflow-x: scroll;
}
.image-slider-scroller::-webkit-scrollbar {
  display: none;
}

.image-slider-pages {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
}

.image-slider-page {
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  scroll-snap-align: center;
}

.image-slider-control {
  position: absolute;
  top: 50%;
  display: flex;
  width: 2rem;
  height: 2rem;
  align-items: center;
  justify-content: center;
  color: rgba(0, 0, 0, 0.06);
  transition: color 0.2s;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  scroll-snap-align: none;
}
.image-slider-control .icon {
  width: 1rem;
  height: 1rem;
}
.image-slider-control:hover {
  color: #8c8c8c;
}

.image-slider-control-left {
  left: 0;
}

.image-slider-control-right {
  right: 0;
}
.page {
  width: 100%;
  flex: 1;
  position: relative;
}

.page-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.user-profile-details {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.user-profile-section {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}
.user-profile-section > p {
  font-family: Nobel;
  font-size: 14px;
  word-break: break-all;
  font-weight: 400;
  line-height: 22px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  flex-wrap: wrap;
}
.user-profile-section > p a {
  color: #c71212;
}

.user-profile-verified {
  display: flex;
  width: 1rem;
  height: 1rem;
  min-width: 1rem;
  min-height: 1rem;
  border-radius: 0.5rem;
  justify-content: center;
  align-items: center;
  color: #fff;
  background-color: #40a9ff;
}
.user-profile-verified .icon {
  width: 0.5rem;
  height: 0.5rem;
}

.user-profile-title {
  font-family: Nobel;
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
}

.user-profile-avatar {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.user-profile-avatar img {
  width: 150px;
}

.user-profile-bio {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.user-profile-bio-content > div {
  font-family: Nobel;
  font-size: 14px;
  word-break: break-all;
  font-weight: 400;
}
.user-profile-bio-content a {
  text-decoration: underline;
}

.user-profile-analytics-stats {
  opacity: 1;
  transition: opacity 0.2s;
}

.user-profile-analytics {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.user-profile-analytics.loading .user-profile-analytics-stats {
  opacity: 0.5;
}
.selector-retailer-option {
  display: flex;
  flex-direction: row;
  width: 290px;
  justify-content: space-between;
  padding: 0.25rem 0.5rem;
}
.selector-retailer-option .selector-retailer-commission {
  font-size: 0.75rem;
  font-style: italic;
  padding: 0.25rem 0.75rem;
  border: 1px solid #000;
  border-radius: 1.125rem;
  box-shadow: 1px 1px 3px 0px rgba(0, 0, 0, 0.16);
}
.selector-retailer-option .selector-retailer-commission > span {
  font-weight: 700;
}

.selector-retailer-header {
  display: flex;
  padding: 0.25rem 0.75rem 0.25rem 1.25rem;
}
.selector-retailer-header > span {
  font-size: 0.875rem;
  font-weight: 500;
  font-style: italic;
  color: #BFBFBF;
}
.selector-retailer-header > span:first-of-type {
  margin-right: 140px;
}
.size-schema-tick {
  width: 1.5rem;
  height: 1.5rem;
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.size-schema-tick .icon {
  width: 0.75rem;
  height: 0.75rem;
}

.size-schema-expanded {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.size-schema-expanded h4 {
  font-weight: 500;
  display: flex;
  justify-content: space-between;
}

.size-schema-scheme {
  display: flex;
  gap: 0.5rem;
}

.size-schema-list {
  display: flex;
  flex: 1;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0.5rem;
}
.size-schema-list li {
  aspect-ratio: 1;
  height: 40px;
  border: 1px solid rgba(0, 0, 0, 0.06);
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
}
.size-schema-list li a {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  width: 100%;
  height: 100%;
}
.size-schema-list li.active {
  background-color: #4d4d4d;
}
.size-schema-list li.active a {
  color: #fff;
}
.size-schema-list[data-system=jeans] li {
  font-size: 0.75rem;
}

.size-schema-collapsed {
  display: flex;
  gap: 0.5rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  padding: 1rem 0;
  align-items: center;
}
.size-schema-collapsed .icon {
  width: 0.75rem;
  height: 0.75rem;
}
.size-schema-collapsed p {
  font-size: 1rem;
}
.size-schema-collapsed b {
  margin-right: 0.5rem;
  font-weight: 500;
}

.size-schema-actions {
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.infinite-scroll-spinner-wrapper {
  width: 10rem;
  margin-left: auto;
  margin-right: auto;
}
.infinite-scroll-spinner-wrapper .ant-spin-text {
  font-size: 0.875rem;
  font-family: HelveticaNeue;
  font-weight: 300;
  color: #4d4d4d;
}

.infinite-scroll-spinner {
  display: block;
  margin-top: 2rem;
  margin-bottom: 1rem;
  margin-left: auto;
  margin-right: auto;
}
.toolbar {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem 1rem;
  flex-wrap: wrap;
}
.toolbar .ant-input-search {
  width: auto;
  min-width: 425px;
}

.toolbar-spacer {
  flex: 1;
  margin-left: auto;
  margin-right: auto;
}

.toolbar-group {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
  flex-wrap: wrap;
}
.notice-button-link {
  background: transparent;
  font-weight: 700;
  color: #fff;
  border: none;
  outline: none;
  text-decoration: underline;
  cursor: pointer;
  padding: 0 0 0 0.3rem;
}

.ant-message-notice-content {
  border-radius: 1rem !important;
  padding: 0.5rem 1rem !important;
}
.ant-message-notice-content span {
  line-height: 18px;
}
.input-checkbox {
  display: block;
  background-color: transparent;
  border: 0;
  padding: 0;
  margin: 0;
  cursor: pointer;
}

.input-checkbox--disabled {
  pointer-events: none;
}

.input-checkbox--with-label {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  text-align: left;
}

.input-checkbox__tick-container {
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #1d1d1d;
  transition: border-color 0.2s;
}

.input-checkbox__tick-container--disabled {
  border: 1px solid #9d9d9d;
  background-color: #fafafa;
}

.input-checkbox__tick {
  color: #1d1d1d;
  opacity: 1;
  transition: opacity 0.2s, color 0.2s;
}

.input-checkbox__tick--disabled {
  color: #505050;
}

.input-checkbox__tick--unchecked {
  opacity: 0;
}

.input-checkbox__label--small {
  font-size: 0.875rem;
}

.input-checkbox__label--medium {
  font-size: 0.875rem;
}

.input-checkbox__tick-container--small {
  width: 1rem;
  min-width: 1rem;
  height: 1rem;
}
.input-checkbox__tick-container--small .loading {
  max-width: 0.75rem;
  max-height: 0.75rem;
  margin-bottom: 45%;
}

.input-checkbox__tick--small {
  width: 0.65rem;
  height: 0.65rem;
}

.input-checkbox__tick-container--medium {
  width: 1.5rem;
  min-width: 1.5rem;
  height: 1.5rem;
}
.input-checkbox__tick-container--medium .loading {
  max-width: 1rem;
  max-height: 1rem;
}

.input-checkbox__tick--medium {
  width: 0.875rem;
  min-width: 0.875rem;
  height: 0.875rem;
}
.form-input {
  gap: 0.25rem;
}

.form-input-label {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
}
.form-input-label span {
  font-size: 0;
  display: block;
  width: 0.875rem;
  height: 0.875rem;
}
.form-input-label .icon {
  width: 0.875rem;
  height: 0.875rem;
}
.input-radio {
  display: block;
  background-color: transparent;
  border: 0;
  padding: 0;
  margin: 0;
  cursor: pointer;
}

.input-radio--disabled {
  pointer-events: none;
}

.input-radio__tick-container {
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #1d1d1d;
  transition: border-color 0.2s;
}

.input-radio__tick-container--disabled {
  border: 1px solid #505050;
}

.input-radio__tick {
  border-radius: 50%;
  background-color: #1d1d1d;
  opacity: 1;
  transition: opacity 0.2s, background-color 0.2s;
}

.input-radio__tick--disabled {
  background-color: #505050;
}

.input-radio__tick--unchecked {
  opacity: 0;
}

.input-radio__tick-container--medium {
  width: 1.5rem;
  height: 1.5rem;
}
.input-radio__tick-container--medium .loading {
  max-width: 20px;
  max-height: 20px;
}

.input-radio__tick-container--small {
  width: 1rem;
  height: 1rem;
}

.input-radio__tick--medium {
  width: 1.2rem;
  height: 1.2rem;
}

.input-radio__tick--small {
  width: 0.7rem;
  height: 0.7rem;
}
.size-select {
  min-width: 0;
}

.size-select-systems {
  min-width: 0;
}
.size-select-systems a {
  display: block;
  text-align: center;
  font-size: 0.875rem;
  padding: 0.25rem;
  flex: 1;
  border-radius: 0.25rem;
  font-weight: 400;
}
.size-select-systems a:last-child {
  min-width: 50px;
}
.size-select-systems a:hover {
  background-color: rgba(0, 0, 0, 0.05);
}
.size-select-systems a.active {
  background-color: #1d1d1d;
  color: #fff;
}
.size-select-systems a.active:hover {
  background-color: rgba(0, 0, 0, 0.85);
}
.page-chat {
  display: flex;
}

html.html-page-chat .header-menu {
  border-bottom: 0;
}
html.html-page-chat .header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
}
.page-consultation {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  min-width: 0;
}

.page-consultation-header {
  margin-top: 1rem;
}

.page-consultation-columns {
  display: flex;
  flex-direction: row;
  gap: 1.5rem;
  flex: 1;
}
@media screen and (min-width: 1200px) {
  .page-consultation-columns {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
}

.page-consultation-info {
  width: 290px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.page-consultation-info .ant-avatar {
  width: 3rem;
  height: 3rem;
  line-height: 4rem;
}
@media screen and (max-width: 768px) {
  .page-consultation-info {
    width: 350px;
  }
}

.page-consultation-status {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}
.page-consultation-status .consultation-status__text {
  font-size: 0.75rem;
}

.page-consultation-empty {
  flex: 1;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}
.page-consultation-empty p {
  font-size: 1.25rem;
}

.page-consultation-looks {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.button--default-shape.button--medium.page-consultation-back {
  display: inline-flex;
  padding-left: 0;
}

.page-consultation-info-cons {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: flex-start;
  padding: 1rem 0.75rem;
  border-radius: 8px;
  border: 0.5px solid #d9d9d9;
  box-shadow: 1px 1px 8px 0px rgba(0, 0, 0, 0.08);
}

.page-consultation-customer {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: flex-start;
  justify-content: flex-start;
}
.page-consultation-customer > div {
  gap: 0.5rem;
  display: flex;
  flex-direction: column;
}

.page-consultation-customer-name {
  font-size: 0.875rem;
}

.page-consultation-customer-email {
  align-self: center;
}

.page-consultation-title {
  font-size: 1.5rem;
  font-family: Mencken;
}

.page-consultation-description {
  font-size: 0.75rem;
  font-weight: 300;
}

.page-consultation-info-label {
  font-weight: 500;
  align-self: flex-start;
}

.page-consultation-date {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
}
.page-consultation-date .input-checkbox__label {
  font-weight: 300;
}

.page-consultation-hide {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
}
.page-consultation-hide > span {
  font-family: Nobel;
  font-size: 0.875rem;
  color: #595959;
  white-space: nowrap;
}

.page-consultation-images {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0.5rem;
  width: 100%;
  padding-top: 1rem;
}

.page-consultation-image {
  aspect-ratio: 0.73;
  min-width: 128px;
  width: calc(33.3333333333% - 0.6666666667rem);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 0.5rem;
  cursor: pointer;
}

.page-consultation-looks {
  gap: 1.5rem;
}
.page-consultation-looks .toolbar {
  margin-left: 0;
}

.page-consultation-look-cards {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}
.page-consultation-look-cards .page-consultation-look-card {
  max-width: 300px;
  min-width: 250px;
  width: calc(50% - 0.5rem);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 5px;
}
@media screen and (min-width: 1200px) {
  .page-consultation-look-cards .page-consultation-look-card {
    width: calc(33.3333333333% - 0.6666666667rem);
    min-width: 300px;
  }
}

.page-consultation-product-cards {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.page-consultation-add-button {
  align-self: flex-start;
}

.page-consultation-add-look {
  aspect-ratio: 0.7352;
  background-color: rgba(0, 0, 0, 0.01);
  border: 1px solid #f0f0f0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}
.page-consultation-add-look span {
  border: 1px solid #bfbfbf;
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: width 0.2s, height 0.2s;
}
.page-consultation-add-look .icon {
  width: 1.5rem;
  height: 1.5rem;
}
.page-consultation-add-look:hover span {
  width: 3.3rem;
  height: 3.3rem;
}

.page-consultation-view-menu {
  height: 40px;
  width: 100%;
  display: flex;
  align-items: stretch;
  gap: 2rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
}
.page-consultation-view-menu a {
  text-transform: uppercase;
  display: flex;
  align-items: center;
  position: relative;
  font-weight: 300;
  font-size: 1rem;
  text-shadow: none;
  transition: text-shadow 0.2s;
}
.page-consultation-view-menu a:hover {
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.page-consultation-view-menu a.active:before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 2px;
  background-color: #1d1d1d;
}

.page-consultation-loading {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex: 1;
}
.page-feed {
  flex: 1;
}
.page-feed .catalogue-layout {
  display: flex;
  gap: 1rem;
}
.page-feed .catalogue-layout-main {
  flex: 1;
}
.page-feed .toolbar {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}
.page-feed .toolbar .ant-select {
  min-width: 7rem;
}

.catalogue-side-filters {
  width: 14rem;
  min-width: 14rem;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.page-feed-scroller {
  max-width: 65.5rem;
  margin-left: auto;
  margin-right: auto;
}

.page-feed-scroller-full-page {
  overflow: visible !important;
}

.page-feed-qr-tip {
  left: 50%;
  bottom: 1rem;
  position: fixed;
  padding: 1rem 1rem 20px;
  border-radius: 1rem;
  box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.1607843137);
  background-color: #f0f0f0;
  border: 2px solid #fff;
  display: flex;
  gap: 1rem;
  z-index: 999;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.page-feed-qr-tip .ant-qrcode {
  padding: 0;
  border-radius: 0;
}

.page-feed-qr-wrapper {
  background-color: #fff;
  padding: 2px;
}

.page-feed-qr-text {
  display: flex;
  flex-direction: column;
  padding-top: 4px;
  gap: 0.7rem;
}
.page-feed-qr-text > div {
  display: flex;
  gap: 1rem;
  align-items: center;
}
.page-feed-qr-text > p {
  max-width: 227px;
  font-weight: 700;
  font-size: 16px;
}
.page-feed-qr-text .logo {
  width: 218px;
  height: 23px;
}
.page-feed-qr-text .icon {
  width: 20px;
  height: 20px;
}

.page-feed .toolbar {
  flex-wrap: wrap;
  gap: 0.5rem;
}
.page-feed .toolbar .toolbar-block {
  width: calc(50% - 0.25rem);
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.page-feed .toolbar .toolbar-block-right {
  justify-content: flex-end;
}
.feed-banner {
  height: 50px;
  padding: 1rem;
  background: #f3f0eb;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-bottom: 1rem;
}
.feed-banner p {
  font-family: "Helvetica Neue";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  width: 95%;
  text-align: center;
}
.feed-banner a {
  height: 20px;
  position: absolute;
  width: 20px;
  right: 15px;
  margin-left: 1rem;
}
.page-look-builder {
  background-color: #f3f0eb;
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.page-look-builder-header {
  background-color: #fff;
  height: 4.25rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
}
.page-look-builder-header .logo-link {
  margin-right: auto;
}
.page-look-builder-header .logo {
  width: 223px;
  height: 23px;
}

.page-look-builder-close {
  font-size: 0;
  margin-left: 1rem;
  height: 3.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.page-look-builder-close .icon {
  width: 1.25rem;
  height: 1.25rem;
}

.page-look-builder-layout {
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-left: 2.5rem;
  gap: 1rem;
  min-height: calc(100vh - 4.25rem);
  position: relative;
}

.page-look-builder-look-container {
  width: 100%;
}

.page-look-builder-look-wrapper {
  display: flex;
  flex-direction: row;
  flex: 1;
  width: 100%;
}

.page-look-builder-look {
  width: 22rem;
  width: clamp(22rem, 35%, 29rem);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 1.125rem;
  padding-bottom: 3rem;
}
.page-look-builder-look .look-card,
.page-look-builder-look .look-card-tagged {
  width: 100%;
}
.page-look-builder-look .look-card-tagged .look-card-tagged-image {
  border-radius: 1rem;
}
.page-look-builder-look .look-card .look-card-products .look-card-product-tr {
  border-top-right-radius: 1rem;
}
.page-look-builder-look .look-card .look-card-products .look-card-product-tl {
  border-top-left-radius: 1rem;
}
.page-look-builder-look .look-card .look-card-products .look-card-product-br {
  border-bottom-right-radius: 1rem;
}
.page-look-builder-look .look-card .look-card-products .look-card-product-bl {
  border-bottom-left-radius: 1rem;
}
.page-look-builder-look .look-card-product {
  transition: opacity 0.2s;
  opacity: 1;
  padding: 0;
  border-width: 0.5px;
  border-color: #d9d9d9;
  transition: border-color 0.2s;
}
.page-look-builder-look .look-card-product.active {
  border-color: #1d1d1d;
}
.page-look-builder-look .look-card-product.droppable-active {
  border-color: #1d1d1d;
  scale: 0.98;
  transition: scale 0.2s;
}
.page-look-builder-look .look-card-product-footer {
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.6);
  gap: 0;
  height: auto;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0.25rem;
}
.page-look-builder-look .look-card-product-footer .look-card-product-brand {
  font-weight: 700;
  font-size: 0.75rem;
  line-height: 1.2;
}
.page-look-builder-look .look-card-product-footer .look-card-product-price {
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.2;
}

.page-look-builder-info {
  flex: 1;
  display: flex;
  padding-left: 0;
  position: relative;
  min-height: calc(100vh - 4.25rem);
}
.page-look-builder-info.expanded {
  position: static;
}
.page-look-builder-info.with-modal {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  min-height: 0;
  height: calc(100vh - 4.25rem);
}

.look-card-product-edit,
.look-card-product-add-to-moodboard,
.look-card-product-drag,
.look-card-product-delete {
  transition: opacity 0.2s;
  position: absolute;
}
.look-card-product-edit .icon,
.look-card-product-add-to-moodboard .icon,
.look-card-product-drag .icon,
.look-card-product-delete .icon {
  width: 1rem;
  height: 1rem;
}

.look-card-product-drag {
  left: 0.75rem;
  top: 0.75rem;
  cursor: move;
}

.look-card-product-delete {
  right: 0.75rem;
  top: 0.75rem;
}

.look-card-product-refresh {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  opacity: 0;
  transition: opacity 0.2s;
  color: #fff;
}
.look-card-product-refresh:hover {
  color: #fff;
}
.look-card-product-refresh .icon {
  width: 1.5rem;
  height: 1.5rem;
}

.look-card-product-lt,
.look-card-product-gt {
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 3.5rem;
  opacity: 0;
  transition: opacity 0.2s;
}
.look-card-product-lt .icon,
.look-card-product-gt .icon {
  width: 1rem;
  height: 1rem;
}

.look-card-product-lt {
  left: 0.25rem;
}

.look-card-product-gt {
  right: 0.25rem;
}

.look-card-product-actions {
  position: absolute;
  inset: 0;
  display: flex;
  justify-content: flex-end;
  padding: 0.5rem;
  gap: 0.5rem;
}
.look-card-product-actions:hover .look-card-product-lt,
.look-card-product-actions:hover .look-card-product-gt {
  opacity: 1;
}

.look-editor-empty-slot {
  background-color: #fff;
  position: relative;
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  border-width: 0.5px;
  border-style: solid;
  border-color: #d9d9d9;
  transition: opacity 0.2s, border-color 0.2s;
  /*.look-card-product-add {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      justify-content: center;
      align-items: center;
      width: 3rem;
      height: 3rem;
      border-radius: 50%;
      border-width: 1px;
      border-style: solid;
      border-color: $color-complimentary;
      color: $color-complimentary;
      transition: color 0.2s, border-color 0.2s;

      &:hover {
          border-color: $color-secondary;
          color: $color-secondary;
      }

      .icon {
          width: 1.5rem;
          height: 1.5rem;
      }
  }*/
}
.look-editor-empty-slot.active {
  border-color: #1d1d1d;
}
.look-editor-empty-slot.droppable-active {
  border-color: #1d1d1d;
  scale: 0.98;
  transition: opacity 0.2s, border-color 0.2s, scale 0.2s;
}

.look-card-tagged .look-editor-empty-slot {
  position: absolute;
  inset: 0;
  background-color: transparent;
}
.look-card-tagged .look-editor-empty-slot .look-card-product-add {
  pointer-events: none;
}
.look-card-tagged .look-editor-empty-slot input {
  width: 50px;
  height: 50px;
}

.look-builder-dialog {
  flex-direction: column;
  background-color: #fff;
  z-index: 1;
  width: 100%;
}
.look-builder-dialog.expanded {
  position: absolute;
  top: 3rem;
  bottom: 3rem;
  left: 0;
  right: 0;
}

.look-builder-dialog-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  border-top: 1px solid rgba(0, 0, 0, 0.06);
  height: 3.5rem;
  min-height: 3.5rem;
  padding: 0 1rem;
  gap: 2rem;
}
.look-builder-dialog-header h4 {
  font-family: Mencken;
  font-weight: 700;
  font-size: 1.125rem;
  margin-right: auto;
}
.look-builder-dialog-header .close {
  margin-left: auto;
  font-size: 0;
}
.look-builder-dialog-header .icon {
  width: 1rem;
  height: 1rem;
}
.look-builder-dialog-header-item {
  padding: 1rem;
  gap: 0.5rem;
  display: flex;
  font-size: 14px;
  font-weight: 400;
  align-items: center;
  justify-content: center;
  flex: 1;
  height: 100%;
}
.look-builder-dialog-header-item.active {
  border-bottom: 2px solid #141414;
}

.look-builder-dialog-footer {
  margin-left: 1rem;
  margin-right: 1rem;
  height: 4rem;
  margin-top: auto;
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #f0f0f0;
}

.look-builder-dialog-header-menu-item {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  font-size: 1rem;
  font-weight: 500;
  padding: 0 0.5rem;
}
.look-builder-dialog-header-menu-item.active:before {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 1px;
  background-color: #1d1d1d;
}

.page-look-builder-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  width: 100%;
}
.page-look-builder-form h3 {
  font-family: Mencken;
  font-size: 1.125rem;
  font-weight: 700;
}
.page-look-builder-form > p {
  font-size: 1rem;
  font-weight: 400;
}
.page-look-builder-form .form-input-label {
  font-size: 14px;
}

.page-look-builder-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.page-look-builder-form-hide {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  margin-left: auto;
}
.page-look-builder-form-hide > span {
  font-family: Nobel;
  font-size: 0.875rem;
  color: #595959;
  white-space: nowrap;
}

.page-look-builder-layout-actions {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-right: 1.5rem;
}
.page-look-builder-layout-actions .button--secondary.button--circle.button--icon-w-text {
  width: 66px;
}

.page-look-builder-layout-actions-buttons {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
}
.page-look-builder-layout-actions-buttons .button--circle.button--icon-w-text {
  width: 66px;
}

.page-look-builder-tagged-actions {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 1.5rem;
  width: 100%;
  margin-top: 1rem;
}

.page-look-builder-actions-zoom {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: clamp(24rem, 35%, 29rem);
  margin-left: auto;
}
.page-look-builder-actions-zoom .ant-slider {
  width: 100%;
}
.page-look-builder-actions-zoom p {
  font-size: 0.75rem;
  font-weight: 300;
  text-align: center;
}

.page-look-builder-close-confirm {
  max-width: 300px;
}
.page-look-builder-close-confirm .ant-popconfirm-title {
  text-align: center;
}
.page-look-builder-close-confirm .ant-popover-inner {
  border-radius: 0;
}
.page-look-builder-close-confirm .ant-popconfirm-buttons {
  text-align: center;
}
.page-look-builder-close-confirm .ant-popconfirm-buttons button:first-child {
  margin-left: 0;
}

.page-look-builder-tooltip {
  padding: 0.5rem 1rem;
  background-color: rgba(29, 29, 29, 0.8);
  color: #fff;
  font-size: 0.875rem;
  font-weight: 400;
  border-radius: 1.5rem;
  position: absolute;
  bottom: 1rem;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.page-look {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  min-width: 0;
  background-color: #f3f0eb;
  padding-bottom: 2rem;
}
.page-look .look-card-tagged .look-card-tagged-tag {
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.2s;
}
.page-look .look-card-tagged:hover .look-card-tagged-tag {
  opacity: 1;
  pointer-events: all;
}

.page-look-columns {
  display: flex;
  flex-direction: row;
  gap: 3rem;
  margin-left: auto;
  margin-right: auto;
}
@media screen and (min-width: 1200px) {
  .page-look-columns {
    width: 100%;
    flex: 1;
    margin-left: 0;
    margin-right: 0;
  }
}

.page-look-header {
  margin-top: 1rem;
}

.button--default-shape.button--medium.page-look-back {
  display: inline-flex;
  padding-left: 0;
}

.page-look-info {
  min-width: 456px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
@media (max-width: 1024px) {
  .page-look-info {
    min-width: 350px;
  }
}

.page-look-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.page-look-title {
  font-size: 1.5rem;
  font-family: Mencken;
  margin-left: 1rem;
}

.page-look-products-wrapper {
  display: flex;
  flex-direction: column;
  gap: 0.87em;
}

.page-look-products {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  align-items: flex-start;
}
.page-look-products p {
  font-family: Nobel;
  font-size: 0.875rem;
  white-space: nowrap;
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.page-look-look-container {
  flex: 1;
}

.page-look-look-wrapper {
  display: flex;
  gap: 0.875rem;
  margin-top: 0.25rem;
}
.page-moodboard .toolbar {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  flex-wrap: nowrap;
  align-items: baseline;
}
.page-moodboard .toolbar > div {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.page-moodboard .toolbar p {
  font-family: HelveticaNeue;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.14px;
}
.page-moodboard .toolbar h2 {
  margin-right: auto;
  font-family: Mencken;
  font-size: 1.875rem;
}
.page-moodboard .catalogue-products {
  justify-content: flex-start;
}
.page-moodboard .page-moodboard-scroller {
  max-width: 1036px;
  margin-left: auto;
  margin-right: auto;
}
.page-moodboard .catalogue-product-card-actions {
  position: absolute;
  display: none;
  top: 0.75rem;
  right: 1rem;
}
.page-moodboard .catalogue-product-card-actions a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.5rem;
  height: 1.5rem;
}
.page-moodboard .catalogue-product-card-actions .icon {
  width: 1rem;
  height: 1rem;
}
.page-moodboard .catalogue-product-card:hover .catalogue-product-card-actions {
  display: flex;
}
.page-moodboard .moodboard-product-rearrange.button--large {
  position: absolute;
  top: 0.65rem;
  left: 0.65rem;
  padding: 0;
  width: 24px;
  height: 24px;
}
.page-moodboard .moodboard-product-rearrange.button--large svg {
  width: 24px;
  height: 24px;
}
.page-moodboards .toolbar {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  justify-content: flex-start;
}
.page-moodboards .moodboard-cards {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 1.5rem 1.25rem;
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}
.page-moodboards .page-moodboards-scroller {
  padding: 4px 0;
  margin-left: auto;
  margin-right: auto;
}
.page-moodboards .moodboards-title {
  font-family: Mencken;
  font-size: 1.875rem;
  margin: 0 1.5rem;
}
.page-moodboards .moodboards-subtitle {
  font-size: 14px;
  font-family: Nobel;
  margin: 0 1.5rem;
  font-weight: 400;
}
.page-moodboards .moodboard-toolbar-actions {
  display: flex;
  gap: 1rem;
  margin-left: auto;
}
.page-moodboards .selector-type {
  text-transform: uppercase;
  max-width: 230px;
}
.page-profile {
  padding-top: 1.5rem;
  padding-bottom: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.profile-content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 1.5rem;
  margin: 0 1.5rem;
}

.profile-title {
  font-family: Mencken;
  font-size: 1.875rem;
  margin: 0 1.5rem;
}

.page-profile-details {
  border: 1px solid #bfbfbf;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  flex: 0 1 324px;
}

.page-profile-avatar {
  border: 1px solid #bfbfbf;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  flex: 0 1 325px;
}

.page-profile-analytics {
  border: 1px solid #bfbfbf;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  flex: 1 1 650px;
}

.page-profile-actions {
  margin-top: auto;
  padding-top: 0;
  padding-bottom: 1rem;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.user-profile-analytics label {
  font-weight: 500;
}

.user-profile-earnings-stats {
  display: flex;
  flex-wrap: wrap;
  background-color: #f0f0f0;
  border-radius: 0.5rem;
  align-items: center;
  justify-content: flex-start;
  padding: 1rem;
  gap: 1rem;
  min-height: 4rem;
}

.user-profile-analytics-main-stats {
  display: flex;
  padding: 0rem;
  gap: 1.5rem;
}
.user-profile-analytics-main-stats p {
  font-weight: 300;
}

.user-profile-analytics-main-stats-inner {
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
}

.user-profile-analytics-info-icon {
  text-align: end;
  height: 20px;
  width: 20px;
}

.user-profile-label {
  font-size: 1rem;
  font-weight: 500;
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
}

.user-profile-date-period-label {
  color: #1b1b1b;
  font-size: 0.9rem;
  font-weight: 300;
}

.user-profile-analytics-stats {
  display: flex;
  background-color: #f0f0f0;
  border-radius: 0.5rem;
  align-items: center;
  justify-content: flex-start;
  padding: 0.5rem 1rem;
  gap: 0.5rem;
  min-height: 4rem;
}
.user-profile-analytics-stats .icon {
  width: 1.5rem;
  height: 1.5rem;
}
.user-profile-analytics-stats p:last-child {
  margin-left: auto;
}
.user-profile-analytics-stats p {
  font-weight: 300;
}
.user-profile-analytics-stats p.user-profile-analytics-label {
  font-weight: 500;
}

.user-profile-analytics-stats-white {
  width: 100%;
  background-color: #ffffff;
}

.user-profile-analytics-periods-container {
  width: 100%;
}

.user-profile-analytics-periods {
  display: flex;
  gap: 0.25rem;
  margin-bottom: 0.625rem;
}
.user-profile-analytics-periods .ant-btn {
  text-transform: none;
}
.user-profile-analytics-periods .ant-btn.ant-btn-active {
  border-color: #1d1d1d;
}
.user-profile-analytics-periods .button {
  background-color: #ffffff;
  color: #000000;
  border-color: #8c8c8c;
}
.user-profile-analytics-periods .button.ant-btn-active {
  background-color: #141414;
  color: #ffffff;
  border-color: #141414;
}
.user-profile-analytics-periods .button:disabled {
  background-color: #f3f0eb;
  color: #8c8c8c;
  border-color: #bfbfbf;
}

.user-profile-analytics-total p {
  font-size: 1.875rem;
  font-weight: 500;
}
.page-recently-deleted .header {
  padding: 16px 28px;
  margin-bottom: 24px;
}
.page-recently-deleted .header h2 {
  font-family: Mencken;
  font-size: 1.875rem;
  margin-bottom: 0.5rem;
}
.page-recently-deleted .header p {
  font-size: 0.75rem;
  font-weight: 300;
}
.page-recently-deleted .consultation-card__image {
  aspect-ratio: 190/260;
}
.page-recently-deleted-look-card {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.page-recently-deleted-look-card .look-card-tagged-image {
  background-size: cover !important;
}

.page-recently-deleted-list {
  max-width: 1036px;
  margin-left: auto;
  margin-right: auto;
}

.page-recently-deleted-badge {
  z-index: 10;
  position: absolute;
  left: 33.5%;
  padding: 4px 8px;
  border-radius: 16px;
  background: rgba(29, 29, 29, 0.8);
  -webkit-backdrop-filter: blur(2px);
          backdrop-filter: blur(2px);
  color: #fff;
  font-size: 12px;
  line-height: 16px;
}
.page-recently-deleted-badge.consultation {
  top: 225px;
}
.page-recently-deleted-badge.moodboard {
  top: 65%;
}
.page-recently-deleted-badge.look {
  top: 86%;
}
.page-signin {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex: 1;
}
.page-signin h1 {
  text-align: center;
}
.page-signin .logo {
  width: 333px;
  height: 35px;
}
.page-signin .button--text {
  margin-right: auto;
  padding: 0 !important;
}
.page-signin .button--text .button__text {
  text-transform: none;
  font-size: 0.875rem;
  text-decoration: underline;
}

.page-signin-form {
  width: 350px;
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 1.5rem;
  justify-content: center;
  align-items: center;
}
.page-signin-form .form-input {
  width: 100%;
}
.page-signin-form .form-input input {
  height: 34px;
  font-size: 0.875rem;
}
.page-signin-form .form-input-label {
  font-size: 0.875rem;
  font-weight: 500;
}
.page-signin-form .ant-btn-default {
  min-width: 10rem;
}

.page-signin-form-actions {
  text-transform: uppercase;
  font-size: 0.875rem;
  line-height: 1.375rem;
}
.page-signin-form-actions-wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.page-signin-form-actions-wrapper .button--text:last-of-type {
  margin-right: 0;
}

.page-signin-autosign {
  flex: 1;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: #F2F0EB;
  position: relative;
}
.page-signin-autosign a {
  position: absolute;
  top: 20%;
}
.page-signin-autosign .page-signin-close {
  width: 24px;
  height: 24px;
  right: 10%;
  top: 10%;
}
.page-signin-autosign h1 {
  margin-top: 50px;
}
.page-signin-autosign > span {
  font-size: 14px;
  font-weight: 400;
  margin-top: 1rem;
  margin-bottom: 24px;
}

.autosign {
  background-color: #F2F0EB;
}

.page-renew-text {
  text-align: center;
}
@media screen and (max-width: 450px) {
  .page-renew-text {
    max-width: 70%;
  }
}

.password-rules {
  font-size: 14px;
  font-weight: 400;
  margin-top: 24px;
}
.password-rules li > span {
  font-weight: 700;
  font-size: x-large;
  line-height: 14px;
  overflow: hidden;
  display: inline-block;
}

.page-signin-newpass h1.ant-typography {
  font-weight: 500;
}

.page-signin-download {
  width: 350px;
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  position: relative;
  margin: auto;
}
.page-signin-download svg.logo {
  width: 333px;
  height: 35px;
}
.page-signin-download h1.ant-typography {
  font-weight: 500;
}
.page-signin-download a:first-of-type {
  position: absolute;
  top: 20%;
}
.page-signin-download > span {
  font-size: 14px;
  text-align: center;
  margin-top: 1rem;
  font-weight: 400;
  max-width: 70%;
}
.page-signin-download .app-store-badge {
  width: 120px;
  height: 40px;
  margin-top: 24px;
}
.page-styling .toolbar {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  justify-content: flex-start;
}
.page-styling .ant-table .ant-table-row {
  cursor: pointer;
}
.page-styling .ant-table .ant-table-row a {
  text-decoration: underline;
  text-decoration-thickness: 1px;
  text-underline-offset: 2px;
}

.page-styling-client {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  text-transform: uppercase;
  font-weight: 400;
}

.page-styling-list-client {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 1rem;
}
.page-styling-list-client .ant-avatar {
  display: flex;
  min-width: 2rem;
  min-height: 2rem;
}

.page-styling-menu {
  margin-left: auto;
  height: 40px;
  display: flex;
  align-items: stretch;
  gap: 2rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
}
.page-styling-menu a {
  text-transform: uppercase;
  display: flex;
  align-items: center;
  position: relative;
  font-weight: 300;
  font-size: 1rem;
  text-shadow: none;
  transition: text-shadow 0.2s;
}
.page-styling-menu a:hover {
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.page-styling-menu a.active:before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 2px;
  background-color: #1d1d1d;
}

.page-styling-view-menu {
  margin-left: auto;
  margin-right: auto;
}

.page-styling-list {
  margin-left: auto;
  margin-right: auto;
  max-width: 68rem;
}

.page-styling-grid {
  margin-left: auto;
  margin-right: auto;
  max-width: 68rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 1036px;
  gap: 1.5rem 1.25rem;
}

.page-styling-cell-status {
  white-space: nowrap;
}
.page-styling-cell-status .ant-select {
  margin-right: 0.5rem;
}
.layout-selector {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  max-height: 100%;
}

.layout-selector-mode {
  padding: 1rem;
  height: 4.5rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
}

.layout-selector-body {
  display: flex;
  flex-direction: row;
  flex: 1;
  height: calc(100% - 7.5rem);
}

.layout-selector-counts {
  width: 120px;
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
  padding-top: 1rem;
}
.layout-selector-counts a {
  display: block;
  padding: 0.5rem 1rem;
  border-radius: 1rem;
}
.layout-selector-counts a.active {
  background-color: rgba(0, 0, 0, 0.06);
}

.layout-selector-templates {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 1rem;
  flex: 1;
  padding: 1rem 0;
  overflow: scroll;
  height: 100%;
}

.layout-selector-template {
  width: calc(25% - 1rem);
  aspect-ratio: 0.58;
  position: relative;
  cursor: pointer;
}

.layout-selector-tpl-grid {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: grid;
  grid-gap: 4px;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-flow: dense;
}
.layout-selector-tpl-grid div {
  transition: background-color 0.3s;
  background-color: #f0f0f0;
}
.layout-selector-tpl-grid:hover div {
  background-color: #bfbfbf;
}
.look-builder-product-page-images {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
}

.look-builder-product-page-images-images {
  display: flex;
  gap: 1rem;
}

.look-builder-product-page-images-image {
  aspect-ratio: 0.75;
  flex: 1;
  max-width: 250px;
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
  transition: background-size 0.2s;
  border: 1px solid rgba(0, 0, 0, 0.06);
  position: relative;
  cursor: pointer;
  scale: 1;
  transition: scale 0.2s;
}
.look-builder-product-page-images-image.active {
  border: 1px solid #4d4d4d;
}
.look-builder-product-page-images-image:hover {
  background-size: 102%;
}
.look-builder-product-page-images-image.drop-active {
  scale: 0.97;
}
.look-builder-product-page-images-image a {
  position: absolute;
  display: flex;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  border: 1px solid rgba(0, 0, 0, 0.06);
  background-color: #fff;
  align-items: center;
  justify-content: center;
  top: 0.5rem;
  right: 0.5rem;
}
.look-builder-product-page-images-image a .icon {
  width: 1rem;
  height: 1rem;
}

.look-builder-product-page-images-controls {
  display: flex;
  align-items: center;
  gap: 5rem;
}

.look-builder-product-page-images-zoom {
  display: flex;
  flex-direction: column;
  flex: 0.7;
  gap: 1rem;
}

.look-builder-product-page-images-comment {
  flex: 0.3;
  background-color: rgba(0, 0, 0, 0.06);
  padding: 1rem;
  border-radius: 0.875rem;
}

.look-builder-product-page-removebg {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
  flex: 1;
  height: calc(100% - 3rem);
}
.look-builder-product-page-removebg .back {
  height: 1rem;
  display: flex;
  gap: 0.5rem;
}
.look-builder-product-page-removebg .back .icon {
  width: 1rem;
  height: 1rem;
}
.look-builder-product-page-removebg .ant-btn {
  margin-top: auto;
}

.look-builder-product-page-removebg-preview {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100% - 3rem - 40px);
}
.look-builder-product-page-removebg-preview img {
  max-width: 50%;
  max-height: 100%;
}

.look-builder-product-page-info {
  display: flex;
  gap: 1rem;
  padding: 1rem;
  flex: 1;
  height: calc(100% - 4rem);
}

.look-builder-product-page-info-images {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  overflow: auto;
  min-width: 200px;
  height: 100%;
}

.look-builder-product-page-info-image {
  width: 200px;
  min-height: 300px;
  aspect-ratio: 0.75;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  border: 1px solid rgba(0, 0, 0, 0.06);
}

.look-builder-product-page-info-info > label {
  font-size: 0.75rem;
  text-transform: uppercase;
}
.look-builder-product-page-info-info h3 {
  font-size: 2rem;
  line-height: 3rem;
  font-weight: 500;
}
.look-builder-product-page-info-info .name {
  font-size: 1.5rem;
  line-height: 2rem;
}
.look-builder-product-page-info-info .price {
  font-size: 1.5rem;
  line-height: 3rem;
  display: flex;
  gap: 0.5rem;
}
.look-builder-product-page-info-info .price span + span {
  text-decoration: line-through;
}
.look-builder-products {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1rem;
  align-items: flex-start;
  position: relative;
}

.look-builder-products-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.7rem;
  padding-top: 1.125rem;
  padding-bottom: 3rem;
  margin-right: 2.5rem;
}

.look-builder-products-title {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.look-builder-products-title h3 {
  font-family: Mencken;
  font-size: 1.125rem;
  font-weight: 700;
}
.look-builder-products-title p {
  font-family: Nobel;
  font-size: 0.875rem;
  white-space: nowrap;
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.look-builder-products-add {
  aspect-ratio: 0.58;
  background-color: #fff;
  border-radius: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 190px;
}

.look-builder-products-tagged-add {
  flex: 1;
  position: absolute;
  inset: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
}
.look-builder-products-tagged-add p {
  font-size: 1.25rem;
  font-weight: 400;
}

.look-builder-products-tip {
  max-width: 151px;
  height: -webkit-max-content;
  height: max-content;
}
.look-builder-products-tip .ant-dropdown-menu {
  border-radius: 0px !important;
  background-color: rgba(29, 29, 29, 0.8);
  -webkit-backdrop-filter: blur(2px);
          backdrop-filter: blur(2px);
}
.look-builder-products-tip .ant-dropdown-menu-item {
  height: -webkit-min-content;
  height: min-content;
  padding: 8px !important;
}
.look-builder-products-tip .ant-dropdown-menu-title-content {
  font-size: 12px;
  font-weight: 300;
  color: #fff;
}
.look-builder-search-layout {
  display: flex;
  height: calc(100% - 4rem);
  gap: 1rem;
  padding: 0 1rem;
}

.look-builder-dialog.with-footer .look-builder-search-layout {
  height: calc(100% - 7.5rem);
}

.look-builder-search-loading {
  position: absolute;
  inset: 0;
  background-color: rgba(255, 255, 255, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.look-builder-search-pages {
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
  min-width: 0;
}

.look-builder-search-menu {
  display: flex;
  flex-direction: column;
  width: 170px;
  min-width: 170px;
  white-space: nowrap;
  height: 100%;
  gap: 0.25rem;
  height: 100%;
}
.look-builder-search-menu a {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  height: 4rem;
  padding-left: 1rem;
  padding-right: 1rem;
  font-size: 0.75rem;
  font-weight: 500;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
}
.look-builder-search-menu a.active {
  border-bottom-color: #1d1d1d;
}
.look-builder-search-menu .icon {
  width: 1.5rem;
  height: 1.5rem;
}
.look-builder-search-menu .icon-link {
  -webkit-transform: scale(-1);
          transform: scale(-1);
}

.look-builder-search-catalogue-filters {
  height: 100%;
}

.look-builder-search-catalogue {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  gap: 0.5rem;
}
.look-builder-search-catalogue .feed-dialog-header {
  padding: 0;
  margin-bottom: 0;
  gap: 0.5rem;
}
.look-builder-search-catalogue .feed-dialog-products {
  flex: 1;
  gap: 0.5rem;
}
.look-builder-search-catalogue .feed-dialog-products .catalogue-product-card {
  width: calc(33.3333333333% - 0.3333333333rem);
}
.look-builder-search-catalogue .feed-dialog-message {
  font-weight: 300;
  padding: 0;
}
.look-builder-search-catalogue .catalogue-product-card-select {
  aspect-ratio: 1;
  width: 2rem;
  left: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.look-builder-search-catalogue .catalogue-product-card-select .card-radio-select {
  position: static;
}

.look-builder-search-url-trusted {
  flex: 1;
  align-items: center;
  justify-content: center;
  display: flex;
}
.look-builder-search-url-trusted p {
  font-size: 1rem;
  font-weight: 400;
}

.look-builder-search-catalogue-moodboards {
  padding-bottom: 1rem;
}
.look-builder-search-catalogue-moodboards .ant-btn {
  width: 100%;
}

.look-builder-search-url {
  display: flex;
  flex: 1;
  flex-direction: column;
  flex: 1;
}

.look-builder-search-url-trusted {
  flex: 1;
  align-items: center;
  justify-content: center;
  display: flex;
}
.look-builder-search-url-trusted p {
  font-size: 1rem;
  font-weight: 400;
}

.look-builder-search-moodboards {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 1rem;
}
.look-builder-search-moodboards .toolbar {
  display: flex;
  flex-direction: column;
}
.look-builder-search-moodboards .toolbar .ant-input-search {
  width: 100%;
}

.look-builder-search-moodboards-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.look-builder-search-moodboards-header h4 {
  font-weight: 500;
}

.look-builder-search-moodboards-cards {
  flex: 1;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0.5rem;
  padding: 0.25rem;
  overflow: auto;
  align-items: flex-start;
  justify-content: flex-start;
  justify-items: flex-start;
  align-content: flex-start;
}
.look-builder-search-moodboards-cards .moodboard-card {
  width: calc(33.3333333333% - 0.3333333333rem);
}

.look-builder-search-moodboard {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 1rem;
}
.look-builder-search-moodboard .toolbar {
  display: flex;
  flex-direction: column;
}
.look-builder-search-moodboard .toolbar .ant-input-search {
  width: 100%;
}

.look-builder-search-moodboard-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.look-builder-search-moodboard-header a {
  display: flex;
  gap: 0.5rem;
}
.look-builder-search-moodboard-header a .icon {
  width: 1rem;
  height: 1rem;
}
.look-builder-search-moodboard-header h4 {
  font-size: 1.5rem;
  font-weight: 500;
  max-width: 75%;
}

.look-builder-search-moodboard-products {
  flex: 1;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0.5rem;
  overflow: auto;
  align-items: flex-start;
  justify-content: flex-start;
  justify-items: flex-start;
  align-content: flex-start;
}
.look-builder-search-moodboard-products .catalogue-product-card {
  width: calc(25% - 0.375rem);
}

.look-builder-search-product-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 3rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  padding: 0.875rem 0;
}
.look-builder-search-product-header a {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}
.look-builder-search-product-header a:first-child {
  font-weight: 700;
}
.look-builder-search-product-header .icon {
  width: 1rem;
  height: 1rem;
}
.look-builder-search-product-header > div {
  display: flex;
  gap: 1rem;
}

.look-builder-search-product {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  gap: 1rem;
}

.look-builder-search-product-scroller {
  height: calc(100% - 3rem);
  overflow: auto;
}
.look-builder-search-product-scroller h4 {
  font-family: Mencken;
  font-weight: 700;
  font-size: 1.125rem;
  margin-right: auto;
  margin-bottom: 1.5rem;
}

.look-builder-search-product-layout {
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 1rem;
}
.look-builder-search-product-layout .catalogue-product-drawer-link {
  max-width: 40%;
  margin-left: auto;
}

.look-builder-search-product-images {
  width: 100%;
  overflow: auto;
}
.look-builder-search-product-images > div {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
}
.look-builder-search-product-images .look-builder-search-product-image {
  cursor: pointer;
  width: 200px;
  min-width: 200px;
  min-height: 300px;
  aspect-ratio: 0.75;
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.06);
  background-position: center;
  background-size: 100%;
  background-repeat: no-repeat;
  transition: background-size 0.2s;
  display: flex;
  padding: 0.5rem;
  justify-content: flex-end;
  align-items: flex-start;
  border-radius: 0.875rem;
}
.look-builder-search-product-images .look-builder-search-product-image:hover {
  background-size: 105%;
}

.look-builder-search-product-sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  background-color: #fff;
  z-index: 1;
}

.look-builder-search-product-toolbar {
  display: flex;
  flex-direction: row;
  gap: 1.5rem;
  align-items: center;
}

.look-builder-search-product-info {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.look-builder-search-product-info > label {
  font-size: 0.75rem;
  text-transform: uppercase;
}
.look-builder-search-product-info h3 {
  font-size: 0.875rem;
  font-weight: 700;
  text-transform: uppercase;
}
.look-builder-search-product-info .name {
  font-size: 0.875rem;
  font-size: 300;
}
.look-builder-search-product-info .description {
  font-size: 0.75rem;
  font-size: 300;
}
.look-builder-search-product-info .price {
  font-size: 1rem;
  font-weight: 700;
  display: flex;
  gap: 0.5rem;
}
.look-builder-search-product-info .price span + span {
  text-decoration: line-through;
  font-weight: 300;
}
.look-builder-search-product-info div.sizes p {
  font-size: 0.875rem;
  font-weight: 700;
}
.look-builder-search-product-info ul.sizes {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}
.look-builder-search-product-info ul.sizes li {
  text-transform: uppercase;
  font-size: 0.875rem;
  font-weight: 300;
}
.look-builder-dialog.expanded .look-builder-search-product-info {
  max-width: 40%;
  min-width: 300px;
  width: clamp(300px, 50%, 400px);
}

.look-builder-search-add-to-moodboard {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  gap: 1rem;
}
.look-builder-search-add-to-moodboard > .toolbar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  height: 3.5rem;
}
.look-builder-search-add-to-moodboard > .toolbar .icon {
  width: 1rem;
  height: 1rem;
}
.look-builder-search-add-to-moodboard > .toolbar a {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
}

.look-builder-search-add-to-moodboard-layout {
  display: flex;
  gap: 1rem;
  flex: 1;
  height: calc(100% - 4.5rem);
}

.look-builder-search-add-to-moodboard-products {
  overflow: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.look-builder-search-add-to-moodboard-moodboards {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  flex: 1;
  height: 100%;
  min-width: 0;
}
.look-builder-search-add-to-moodboard-moodboards h4 {
  font-weight: 500;
}
.look-builder-search-add-to-moodboard-moodboards > .toolbar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.look-builder-search-add-to-moodboard-moodboards > .toolbar .icon {
  width: 1rem;
  height: 1rem;
}
.look-builder-search-add-to-moodboard-moodboards > .toolbar a {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
}

.look-builder-search-add-to-moodboard-cards {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0.5rem;
  flex: 1;
  overflow: auto;
  padding: 0.25rem;
  align-content: flex-start;
  min-width: 0;
}
.look-builder-search-add-to-moodboard-cards .moodboard-card {
  width: calc(50% - 0.4rem);
  min-width: 0;
}
.look-builder-search-add-to-moodboard-cards .moodboard-card .input-radio {
  position: absolute;
  left: 0.5rem;
  top: 0.5rem;
}
.look-builder-search-add-to-moodboard-cards .moodboard-card .moodboard-card-link {
  inset: 0;
  aspect-ratio: auto;
  z-index: 2;
}
.look-builder-loading-page {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
}

.look-builder-type-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
  padding-top: 3rem;
  width: 100%;
}
.look-builder-type-page h2 {
  font-family: Mencken;
  font-size: 1.5rem;
  font-weight: 700;
}

.look-builder-type-page-modes {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 1rem;
}

.button--primary.button--circle.button--icon-w-text.look-builder-type-page-mode-button {
  width: 100px;
}

.look-builder-type-page-mode-button--tagged .button__icon--inactive {
  opacity: 0.5;
}
.look-builder-type-page-mode-button--tagged .button__text--active {
  font-weight: 700;
}

.look-builder-type-page-mode-button--layout .button__icon--inactive {
  opacity: 0.5;
}
.look-builder-type-page-mode-button--layout .button__text--active {
  font-weight: 700;
}

.look-builder-type-page-mode-button--canva .button__icon--inactive {
  opacity: 0.5;
}

.look-builder-type-page-tagged {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
}
.look-builder-type-page-tagged img {
  width: 409px;
}

.look-builder-type-page-description {
  font-size: 1rem;
  font-weight: 400;
}

.look-builder-type-page-notice {
  padding: 9px 16px;
  background-color: rgba(29, 29, 29, 0.8);
  color: #fff;
  font-size: 0.875rem;
  font-weight: 400;
  border-radius: 1.5rem;
}

.page-builder-type-page-layout {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  width: 100%;
}
.page-builder-type-page-layout .look-builder-type-page-notice {
  margin-top: 1rem;
}

.look-builder-type-page-layout-counts {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
}
.look-builder-type-page-layout-counts a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background-color: #fff;
  border-radius: 0.5rem;
  border: 1px solid #D9D9D9;
  font-size: 1rem;
  color: #1d1d1d;
  font-weight: 400;
}
.look-builder-type-page-layout-counts a.active {
  border: 1px solid #1d1d1d;
}

.look-builder-type-page-selector-templates {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: center;
  gap: 1rem;
  flex: 1;
  max-width: 75%;
  width: 100%;
}

.look-builder-type-page-template {
  width: calc(12.5% - 1rem);
  aspect-ratio: 0.73;
  position: relative;
  cursor: pointer;
  border-radius: 0.5rem;
  border: 1px solid #bfbfbf;
  overflow: hidden;
  background-color: #bfbfbf;
}
.look-builder-type-page-template.active {
  border: 1px solid #1d1d1d;
  background-color: #1d1d1d;
}

.look-builder-type-page-tpl-grid {
  display: grid;
  grid-gap: 1px;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-flow: dense;
  width: 100%;
  height: 100%;
}
.look-builder-type-page-tpl-grid div {
  transition: background-color 0.3s;
  background-color: #fff;
}
.look-builder-type-page-tpl-grid:hover div {
  background-color: #f0f0f0;
}

.look-builder-type-page-canva {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  width: 300px;
  margin-top: 1.5rem;
}
.look-builder-type-page-canva p {
  font-size: 1rem;
  font-weight: 400;
  text-align: center;
}

.look-builder-types {
  display: flex;
  gap: 1rem;
  align-items: center;
}
.look-builder-types-type {
  display: flex;
  flex-direction: column;
  padding: 1rem 0.75rem;
  gap: 1rem;
  border-radius: 1rem;
  align-items: center;
  cursor: pointer;
}
.look-builder-types-type .look-builder-types-img {
  background-color: #fff;
  padding: 12px 9px;
  box-sizing: content-box;
  box-shadow: 1px 1px 6px 0px rgba(0, 0, 0, 0.16);
  border: 2px solid #fff;
}
.look-builder-types-type .look-builder-types-img.active {
  border: 2px solid #000;
}
.look-builder-types-type span {
  font-size: 12px;
  font-weight: 400;
}
.look-builder-types-type img {
  border-radius: 1rem;
}
.consultation-actions {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.consultation-actions .button--secondary.button--circle.button--icon-w-text {
  width: 66px;
}
.consultation-card {
  width: 190px;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  position: relative;
}
.consultation-card .card-link {
  position: absolute;
  inset: 0;
}

.consultation-card__image {
  aspect-ratio: 0.58;
  width: 100%;
  background-color: #efefef;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.consultation-card__image .icon {
  color: #fff;
  width: 100px;
  height: 100px;
}

.consultation-card__footer {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.consultation-card__footer-counts {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
  justify-content: flex-start;
}

.consultation-card__count {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.25rem;
  font-family: Nobel;
  font-weight: 300;
  font-size: 0.875rem;
  letter-spacing: 0.56px;
  color: #1d1d1d;
}
.consultation-card__count .icon {
  width: 1rem;
  height: 1rem;
  color: #1d1d1d;
}

.consultation-card__date {
  font-family: Nobel;
  font-size: 0.875rem;
  letter-spacing: 0.56px;
  color: #595959;
  margin-left: auto;
}

.consultation-card__name {
  font-size: 0.875rem;
  line-height: 1;
  letter-spacing: 0.14px;
  font-weight: 700;
  color: #1d1d1d;
  text-transform: uppercase;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.consultation-card__description {
  font-weight: 300;
  font-size: 0.75rem;
  letter-spacing: 0.24px;
  color: #1d1d1d;
  line-height: 1.2;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.consultation-dialog .ant-modal {
  max-width: 400px;
  min-width: 400px;
}

.consultation-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.consultation-form-customer {
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  padding: 1rem 0;
  display: flex;
  gap: 1rem;
  align-items: center;
}
.consultation-form-customer a {
  font-weight: 300;
  text-decoration: underline;
  text-decoration-thickness: 1px;
  text-underline-offset: 2px;
}

.consultation-form-customer-contact {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  font-weight: 500;
}

/*
.consultation-form-tabs {
    border-bottom: 1px solid $color-light;
    gap: 1rem;
    height: 3.5rem;
    display: flex;
    align-items: center;

    a {
        display: block;
        height: 3.5rem;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        position: relative;

        &.active {
            &:before {
                content: "";
                display: block;
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                height: 1px;
                background-color: $color-primary;
            }
        }
    }
}
*/
.consultation-form-content {
  display: flex;
  flex-direction: row;
  gap: 2.5rem;
  align-items: flex-start;
}

.consultation-form-fields {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.consultation-form-fields .form-input-label {
  font-weight: 400;
}
.consultation-form-fields textarea {
  min-height: 64px;
}

.consultation-form-statuses {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
  justify-content: center;
}

.consultation-form-status {
  padding: 0.25rem 1rem;
  border-radius: 0.5rem;
  border: 1px solid #EFEFEF;
  background-color: #fff;
  cursor: pointer;
  transition: border-color 0.2s, background-color 0.2s;
}
.consultation-form-status:hover {
  background-color: #f5f5f5;
}

.consultation-form-status--active {
  border: 1px solid #1d1d1d;
}

/*
.consultation-form-sizes {
    flex: 0.6;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.consultation-form-add-sizes {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    a {
        display: flex;
        gap: 0.5rem;
        align-items: center;
        border-bottom: 1px solid $color-light;
        padding: 1rem 0;

        span {
            width: 1.5rem;
            height: 1.5rem;
            border: 1px solid $color-light;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
    .icon {
        width: 0.75rem;
        height: 0.75rem;
    }
}
*/
.consultation-form-images {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.consultation-form-images .icon {
  width: 2rem;
  height: 2rem;
}
.consultation-form-images-sub-label {
  font-weight: 300;
  font-size: 14px;
}

.consultation-form-dropzone {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  gap: 1rem;
  border: 1px solid #bfbfbf;
  border-radius: 0.875rem;
  min-height: 12rem;
}
.consultation-form-dropzone input {
  width: 3rem;
  height: 3rem;
}
.consultation-form-dropzone p {
  position: static;
  -webkit-transform: none;
          transform: none;
  font-size: 1rem;
  color: #1d1d1d;
}
.consultation-form-dropzone .icon {
  display: block;
}

.consultation-form-dropzone-small {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f0f0f0;
  border-radius: 0.875rem;
  aspect-ratio: 1.1923076923;
  width: calc(33.3333333333% - 0.375rem);
}

.consultation-form-image-list {
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
}

.consultation-form-images-wrapper {
  display: flex;
  flex-direction: column;
}

.consultation-form-image-tip {
  font-size: 14px;
  margin-top: 12px;
  font-weight: 300;
  text-align: center;
}

.consultation-form-image {
  display: flex;
  flex-direction: column;
  aspect-ratio: 1.1923076923;
  border-radius: 0.875rem;
  background-color: #f0f0f0;
  width: calc(33.3333333333% - 0.375rem);
  position: relative;
}
.consultation-form-image div {
  flex: 1;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;
  border-radius: 0.875rem;
}
.consultation-form-image span {
  display: block;
  color: #fff;
  position: absolute;
  bottom: 0.875rem;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  border-radius: 1rem;
  text-align: center;
  background-color: rgba(29, 29, 29, 0.8);
  -webkit-backdrop-filter: blur(2px);
          backdrop-filter: blur(2px);
  font-size: 0.75rem;
  font-weight: 400;
  padding: 0.25rem 0.5rem;
}
.consultation-form-image a {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
  background-color: #fff;
}
.consultation-form-image a .icon {
  width: 0.75rem;
  height: 0.75rem;
}

.consultation-form-footer {
  display: flex;
  justify-content: center;
}
.consultations-dialog.empty .ant-modal {
  height: 265px;
}
.consultations-dialog .ant-modal {
  height: 600px;
}
.consultations-dialog .ant-modal-content {
  padding: 1.375rem 0 0 0;
  height: 100%;
  border-radius: 0;
}
.consultations-dialog .ant-modal-body {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}
.consultations-dialog-body {
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 1rem;
}
.consultations-dialog .feed-dialog-close {
  margin-left: auto;
}
.consultations-dialog .consultations-cards {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem 1.375rem;
  overflow: auto;
  padding: 4px 1.375rem;
}
.consultations-dialog .toolbar {
  width: 100%;
  padding: 0 1.375rem;
}
.consultations-dialog .toolbar .ant-input-search {
  flex: 1;
}
.consultations-dialog .page-styling-menu {
  margin: 0 16px;
}
.consultations-dialog .consultation-card {
  cursor: pointer;
}
.consultations-dialog .look-card {
  max-width: 190px;
}
.consultations-dialog .consultations-cards-empty {
  margin: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.consultations-dialog .consultations-cards-empty p {
  font-size: 1.25rem;
}
.consultations-dialog .consultations-cards-empty-action {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 1.5rem;
  font-size: 1.25rem;
  margin: 1.25rem 1.5rem 1.5rem 1.5rem;
}
.consultations-dialog .consultations-cards-empty-action:first-child {
  margin-top: 0;
}
.consultation-status {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
  background-color: #fff;
}

.consultation-status__text {
  font-weight: 300;
  font-size: 0.75rem;
  color: #1d1d1d;
  letter-spacing: 0.24px;
}

.consultation-status__indicator {
  display: block;
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
}

.consultation-status-new__indicator {
  background-color: #C71212;
}

.consultation-status-request__indicator {
  background-color: #C71212;
}

.consultation-status-inprogress__indicator {
  background-color: #F7DD13;
}

.consultation-status-completed__indicator {
  background-color: #7FC314;
}

.consultation-status-cancelled__indicator {
  background-color: #ccc;
}
.catalogue-products {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1rem;
  gap: 1.25rem 1rem;
  justify-content: center;
  align-items: flex-start;
}

.catalogue-product-card {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 190px;
  position: relative;
  transition: opacity 0.2s, -webkit-transform 0.2s;
  transition: transform 0.2s, opacity 0.2s;
  transition: transform 0.2s, opacity 0.2s, -webkit-transform 0.2s;
}

.catalogue-product-card-fluid {
  width: 100%;
}

.catalogue-product-card-link {
  position: absolute;
  aspect-ratio: 0.58;
  width: 100%;
  top: 0;
  left: 0;
}

.catalogue-product-card-image-slider {
  aspect-ratio: 0.58;
  width: 100%;
  border-radius: 0.875rem;
  border: 1px solid rgba(0, 0, 0, 0.06);
}

.catalogue-product-card-image {
  aspect-ratio: 0.58;
  width: 100%;
  border-radius: 0.875rem;
  border: 1px solid rgba(0, 0, 0, 0.06);
  overflow: hidden;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
}

a.catalogue-product-card-retailer {
  width: 100%;
  max-width: 100%;
  background-color: #1d1d1d;
  border-radius: 0.25rem;
  display: flex;
  padding: 0 0.25rem;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.4rem;
  color: #ffffff;
  height: 1.5rem;
  line-height: 1.5rem;
  font-size: 0.75rem;
}
a.catalogue-product-card-retailer span {
  display: block;
  text-transform: uppercase;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 1em;
}
a.catalogue-product-card-retailer.len-16 span {
  font-size: 0.85em;
}
a.catalogue-product-card-retailer .icon {
  width: 0.6rem;
  height: 0.6rem;
  min-width: 0.6rem;
  min-height: 0.6rem;
}
a.catalogue-product-card-retailer:hover {
  color: rgba(255, 255, 255, 0.8);
}

.catalogue-product-card-large a.catalogue-product-card-retailer {
  font-size: 1rem;
  height: 2.5rem;
  border-radius: 0.5rem;
}
.catalogue-product-card-large a.catalogue-product-card-retailer .icon {
  width: 0.8rem;
  height: 0.8rem;
}

.catalogue-product-footer {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  flex: 1;
}

.catalogue-product-footer-designer {
  font-size: 0.875rem;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 0.875rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.catalogue-product-footer-name {
  font-size: 0.875rem;
  font-weight: 300;
  line-height: 1.25rem;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.catalogue-product-footer-prices {
  display: flex;
  flex-direction: row;
  gap: 0.25rem;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: auto;
}

.catalogue-product-footer-price {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  font-size: 0.875rem;
  line-height: 0.875rem;
  font-weight: 700;
  margin-top: 0.35rem;
  min-width: -webkit-max-content;
  min-width: max-content;
}
.catalogue-product-footer-price span {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.catalogue-product-footer-price span:first-child {
  color: #c71212;
  text-decoration: none;
}
.catalogue-product-footer-price span:last-child {
  color: #1d1d1d;
  font-weight: 300;
  text-decoration: line-through;
}
.catalogue-product-footer-price span:first-child:last-child {
  text-decoration: none;
  font-weight: 700;
  color: #1d1d1d;
}

.catalogue-product-footer-approx-price {
  font-size: 0.875rem;
  line-height: 1.275rem;
  font-weight: 300;
  font-style: italic;
}

.catalogue-product-footer-commission {
  border: 1px solid #1d1d1d;
  border-radius: 0.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.75rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  flex-wrap: wrap;
  cursor: default;
  background-color: #fff;
}
.catalogue-product-footer-commission svg {
  margin-left: 7px;
}

.catalogue-product-card-select {
  display: block;
  position: absolute;
  aspect-ratio: 0.58;
  width: 100%;
  top: 0;
  left: 0;
}
.catalogue-product-card-select .input-radio {
  position: absolute;
  top: 0.75rem;
  right: 1rem;
}
.catalogue-product-card-select .card-radio-select {
  position: absolute;
  top: 0.75rem;
  right: 1rem;
}

.catalogue-product-reaction {
  color: #1d1d1d;
  position: absolute;
  top: 0.75rem;
  right: 1rem;
}
.catalogue-product-reaction .icon {
  width: 1rem;
  height: 1rem;
}

.catalogue-product-card.droppable-active {
  -webkit-transform: scale(0.97);
          transform: scale(0.97);
  opacity: 0.9;
}

.commission-badge {
  max-width: 151px;
  height: -webkit-max-content;
  height: max-content;
}
.commission-badge .ant-dropdown-menu {
  border-radius: 0px !important;
}
.commission-badge .ant-dropdown-menu-item {
  height: -webkit-min-content;
  height: min-content;
  padding: 8px !important;
}
.commission-badge .ant-dropdown-menu-title-content {
  font-size: 12px;
  font-weight: 300;
}
.catalogue-product-drawer .image-slider-control-left {
  left: -2.5rem;
}
.catalogue-product-drawer .image-slider-control-right {
  right: -2.5rem;
}

.catalogue-product-drawer-content {
  width: 18rem;
  padding-left: 0;
  padding-right: 0;
  margin-left: auto;
  margin-right: auto;
}

.catalogue-product-drawer-actions {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 1.5rem;
  align-items: flex-start;
}
.catalogue-product-drawer-actions .button--secondary.button--circle.button--icon-w-text, .catalogue-product-drawer-actions .button--primary.button--circle.button--icon-w-text {
  width: 66px;
}

.ant-typography.catalogue-product-drawer-link {
  margin-bottom: 0;
  border: 1px solid #1d1d1d;
  border-radius: 0.5rem;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  padding-left: 0.5rem;
  padding-right: 1.5rem;
  position: relative;
}
.ant-typography.catalogue-product-drawer-link > span {
  display: block;
  white-space: nowrap;
  overflow-x: auto;
  padding-right: 2rem;
}
.ant-typography.catalogue-product-drawer-link > span::-webkit-scrollbar {
  display: none;
}
.ant-typography.catalogue-product-drawer-link [role=button] {
  position: absolute;
  right: 0.2rem;
  top: 50%;
  bottom: 0;
  height: 1.1rem;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.ant-typography.catalogue-product-drawer-link [role=button] span {
  vertical-align: 0;
}
.feed-messages {
  font-weight: 300;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.feed-message {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.feed-message h3 {
  font-size: 1.5rem;
  font-weight: 500;
}
.catalogue-selected-filters {
  display: flex;
  gap: 0.5rem;
  align-items: flex-start;
  flex-wrap: wrap;
}

.catalogue-selected-filters-item {
  border: 1px solid #1d1d1d;
  border-radius: 0.25rem;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  font-size: 0.75rem;
  padding: 0.25rem 0.5rem;
  background-color: #fff;
}
.catalogue-selected-filters-item .icon {
  width: 0.55rem;
  min-width: 0.55rem;
  height: 0.55rem;
}

.catalogue-selected-filters-clear-all {
  font-size: 0.75rem;
  padding: 0.25rem 0.5rem;
  background-color: #fff;
  text-decoration: underline;
  text-transform: uppercase;
}
.catalogue-selected-filters-clear-all:hover {
  text-decoration: underline;
}
.moodboard-card {
  position: relative;
  width: 190px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.moodboard-card-toolbar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
}

.moodboard-card-name {
  font-family: HelveticaNeue;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 0.875rem;
  line-height: 1.125rem;
  font-weight: 700;
  text-transform: uppercase;
  margin-top: 3px;
}

.moodboard-card-note {
  font-size: 12px;
  font-weight: 300;
  color: #8C8C8C;
  text-transform: uppercase;
  line-height: 110%;
}

.moodboard-card-count {
  font-size: 0.875rem;
  font-weight: 300;
  line-height: 1.375rem;
  display: flex;
  align-items: center;
}

.moodboard-card-count-icon {
  width: 1rem;
  height: 1rem;
  margin-right: 5px;
}

.moodboard-card-info .icon {
  width: 1rem;
  height: 1rem;
}

.moodboard-card-cover-image {
  aspect-ratio: 1;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 0.5rem;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.moodboard-card-image-empty {
  aspect-ratio: 1;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 0.5rem;
  gap: 0.5rem;
  background-color: #f0f0f0;
}
.moodboard-card-image-empty .icon {
  width: 2rem;
  height: 2rem;
  color: #bfbfbf;
}

.moodboard-card-image {
  flex: 1;
  aspect-ratio: 0.58;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 0.5rem;
  overflow: hidden;
  max-width: 33.3333333333%;
}

.moodboard-card-placeholder {
  flex: 1;
}

.moodboard-card-link {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
}
.moodboards-dialog.empty .ant-modal {
  height: 265px;
}
.moodboards-dialog .ant-modal {
  height: 500px;
}
.moodboards-dialog .ant-modal-content {
  padding: 1.375rem 0 0 0;
  height: 100%;
  border-radius: 0;
}
.moodboards-dialog .ant-modal-body {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}
.moodboards-dialog .moodboards-dialog-body {
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 1rem;
}
.moodboards-dialog .feed-dialog-close {
  margin-left: auto;
}
.moodboards-dialog .moodboard-cards {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  overflow: auto;
  padding: 4px 1rem;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.moodboards-dialog .moodboard-cards::-webkit-scrollbar {
  display: none;
}
.moodboards-dialog .toolbar {
  width: 100%;
  padding: 0 1rem;
}
.moodboards-dialog .toolbar .ant-input-search {
  width: 65%;
}
.moodboards-dialog .moodboard-card {
  cursor: pointer;
}
.moodboards-dialog .moodboard-cards-empty {
  margin: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  font-size: 20px;
}
.moodboards-dialog .moodboard-cards-empty p {
  font-size: 20px;
  max-width: 50%;
  text-align: center;
  line-height: 26px;
}
.moodboards-dialog .moodboard-cards-empty-action {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  font-size: 20px;
  margin: 20px 0 24px 0;
}
.moodboard-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 0 24px;
}
.moodboard-form .form-input > span, .moodboard-form .form-input > label {
  font-size: 14px;
}
.moodboard-form .form-input > label {
  font-weight: 300;
}
.moodboard-form .form-input .ant-typography:first-child {
  font-weight: 500;
}
.moodboard-form .form-input > input {
  padding: 9px 12px;
  height: 36px;
  font-size: 14px;
}

.moodboard-form-cover {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  font-size: 14px;
}

.moodboard-form-cover-dropzone {
  width: 190px;
  aspect-ratio: 1;
  background-color: #f0f0f0;
  border-radius: 0.5rem;
  box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.16);
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.moodboard-form-cover-dropzone input {
  z-index: 1;
  height: 40px;
  width: 40px;
}
.moodboard-form-cover-dropzone-wrapper {
  display: flex;
  flex-direction: column;
  max-width: 214px;
  border-radius: 0.5rem;
  padding: 12px;
  background-color: #fff;
  box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.16);
}
.moodboard-form-cover-dropzone-actions {
  display: flex;
  gap: 0.5rem;
  justify-content: flex-end;
  margin-bottom: 0.5rem;
}

.moodboard-form-cover-dropzone-icon {
  width: 0.875rem;
  height: 0.875rem;
  color: #1d1d1d;
  transition: background-color 0.2s;
}
.moodboard-form-cover-dropzone-icon .icon {
  width: 0.875rem;
  height: 0.875rem;
}

.moodboard-form-cover-delete {
  z-index: 1000;
}

.moodboard-form-cover-dropzone:hover .moodboard-form-cover-dropzone-icon.moodboard-form-cover-edit {
  background-color: #1d1d1ddd;
  color: #ffffff;
}

.moodboard-form--saving .moodboard-form-cover-dropzone-icon {
  background-color: #1d1d1ddd;
}

.moodboard-form-cover label {
  font-weight: 500;
}
.moodboard-share-modal .ant-modal-confirm-body {
  margin-bottom: 0;
}
.moodboard-share-modal .ant-modal-confirm-btns {
  display: none;
}
.moodboard-share-modal .ant-modal-confirm-title {
  position: absolute;
  top: 0;
}
.moodboard-share-modal .ant-modal-confirm-content {
  margin: 0.875rem 0 0;
  padding: 0;
  max-width: none;
}
.moodboard-share-modal .moodboard-share-content {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
}
.moodboard-share-modal .moodboard-share-content p:first-child {
  margin-bottom: 0.875rem;
}
.moodboard-share-modal .moodboard-share-content div {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  width: 100%;
  margin-bottom: 12px;
}
.moodboard-share-modal .moodboard-share-content div .ant-input {
  height: 36px;
  font-size: 14px;
}
.moodboard-share-modal .moodboard-share-content div .button.button--default-shape.button--large {
  flex: 1 0 100px;
}
.moodboard-share-modal .moodboard-share-content a {
  text-decoration: underline;
}
.moodboard-share-modal .ant-modal-close-x {
  color: #1d1d1d;
}

.delete-moodboard-modal .ant-modal-confirm-btns {
  padding: 0 16px 16px;
}
.delete-moodboard-modal .ant-modal-confirm-btns .ant-btn-primary {
  height: 36px;
}
.delete-moodboard-modal .ant-modal-confirm-content {
  max-width: 70%;
}
.look-actions {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 1rem;
  flex: 0 1;
  min-width: -webkit-min-content;
  min-width: min-content;
}
.look-actions .button--secondary.button--circle.button--icon-w-text {
  width: 66px;
}
.look-card-footer {
  display: flex;
  flex-direction: column;
  gap: 5px;
  font-size: 14px;
}
.look-card-footer-counts {
  display: flex;
  justify-content: space-between;
  font-weight: 300;
}
.look-card-footer-counts-wrapper {
  display: flex;
  gap: 0.875rem;
}
.look-card-footer-count {
  display: flex;
  align-items: center;
  gap: 3px;
}
.look-card-footer .icon {
  width: 1rem;
  height: 1rem;
}
.look-card-footer p {
  text-transform: uppercase;
  font-weight: 700;
}
.look-card-tagged {
  aspect-ratio: 0.7352;
  position: relative;
  width: 100%;
  z-index: 1;
}
.look-card-tagged .loading {
  position: absolute;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.look-card-tags {
  overflow: hidden;
  position: absolute;
  inset: 0;
  display: block;
}

.look-card-tagged-image {
  position: absolute;
  inset: 0;
  display: block;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 0.5rem;
}

.look-card-tagged-tag {
  background-color: rgba(29, 29, 29, 0.8);
  -webkit-backdrop-filter: blur(2px);
          backdrop-filter: blur(2px);
  position: absolute;
  border-radius: 0.75rem;
  padding: 0.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  color: #fff;
  z-index: 1;
  cursor: pointer;
  min-width: 50px;
  min-height: 50px;
  -webkit-user-select: none;
          user-select: none;
  transition: background-color 0.3s;
}
.look-card-tagged-tag.rotating {
  color: #fff;
  transition: background-color 0.3s, top 0.3s, left 0.3s;
}
.look-card-tagged-tag:hover {
  color: #fff;
  z-index: 2;
  background-color: rgba(15, 15, 15, 0.8);
}
.look-card-tagged-tag.active {
  color: #fff;
  background-color: rgb(0, 0, 0);
}

.look-card-tagged-tag-control {
  width: 1rem;
}

.look-card-tagged-tag-close .icon {
  width: 1rem;
  height: 1rem;
  color: #fff;
}

.look-card-tagged-tag-tip {
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}
.look-card-tagged-tag-tip::before {
  content: "";
  display: block;
  width: 1.25rem;
  height: 1.25rem;
  background-color: rgba(29, 29, 29, 0.8);
  transition: background-color 0.3s, top 0.3s, left 0.3s;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  -webkit-transform-origin: center;
          transform-origin: center;
}
.look-card-tagged-tag-tip.bl {
  top: 100%;
  left: 10px;
}
.look-card-tagged-tag-tip.bl:before {
  -webkit-transform: rotate(45deg) translate(-50%, -50%);
          transform: rotate(45deg) translate(-50%, -50%);
}
.look-card-tagged-tag-tip.br {
  top: 100%;
  right: 10px;
}
.look-card-tagged-tag-tip.br:before {
  -webkit-transform: rotate(45deg) translate(-50%, -50%);
          transform: rotate(45deg) translate(-50%, -50%);
}
.look-card-tagged-tag-tip.tl {
  bottom: 100%;
  left: 10px;
}
.look-card-tagged-tag-tip.tl:before {
  -webkit-transform: rotate(45deg) translate(50%, 50%);
          transform: rotate(45deg) translate(50%, 50%);
}
.look-card-tagged-tag-tip.tr {
  bottom: 100%;
  right: 10px;
}
.look-card-tagged-tag-tip.tr:before {
  -webkit-transform: rotate(45deg) translate(50%, 50%);
          transform: rotate(45deg) translate(50%, 50%);
}
.look-card-tagged-tag-tip.lb {
  right: 100%;
  bottom: 10px;
}
.look-card-tagged-tag-tip.lb:before {
  -webkit-transform: rotate(45deg) translate(50%, -50%);
          transform: rotate(45deg) translate(50%, -50%);
}
.look-card-tagged-tag-tip.lt {
  right: 100%;
  top: 10px;
}
.look-card-tagged-tag-tip.lt:before {
  -webkit-transform: rotate(45deg) translate(50%, -50%);
          transform: rotate(45deg) translate(50%, -50%);
}
.look-card-tagged-tag-tip.rb {
  left: 100%;
  bottom: 10px;
}
.look-card-tagged-tag-tip.rb:before {
  -webkit-transform: rotate(45deg) translate(-50%, 50%);
          transform: rotate(45deg) translate(-50%, 50%);
}
.look-card-tagged-tag-tip.rt {
  left: 100%;
  top: 10px;
}
.look-card-tagged-tag-tip.rt:before {
  -webkit-transform: rotate(45deg) translate(-50%, 50%);
          transform: rotate(45deg) translate(-50%, 50%);
}

.look-card-tagged-tag:hover .look-card-tagged-tag-tip::before {
  background-color: rgba(15, 15, 15, 0.8);
}

.look-card-tagged-tag.active .look-card-tagged-tag-tip::before {
  background-color: rgb(0, 0, 0);
}

.look-card-tagged-tag-brand {
  font-weight: 700;
  letter-spacing: 0.48px;
  line-height: 1rem;
  font-size: 0.75rem;
  vertical-align: middle;
  white-space: nowrap;
}

.look-card-tagged-tag-price {
  font-weight: 700;
  letter-spacing: 0.48px;
  line-height: 1rem;
  font-size: 0.75rem;
  vertical-align: middle;
  white-space: nowrap;
}

.look-card-tagged-tag-sold-out {
  font-weight: 700;
  letter-spacing: 0.48px;
  line-height: 1rem;
  font-size: 0.75rem;
  vertical-align: middle;
  text-decoration: line-through;
}

.look-card-tagged-tag-price-old-new {
  display: flex;
  flex-direction: row;
  gap: 0.15rem;
}

.look-card-tagged-tag-price-discounted {
  font-weight: 700;
  letter-spacing: 0.48px;
  line-height: 1rem;
  font-size: 0.75rem;
  vertical-align: middle;
  color: #c71212;
}

.look-card-tagged-tag-price-old {
  font-weight: 300;
  text-decoration: line-through;
}

.look-card-tagged-tag-retailer {
  text-transform: uppercase;
  letter-spacing: 0.12px;
  line-height: 1rem;
  font-size: 0.75rem;
  vertical-align: middle;
  white-space: nowrap;
}

.look-card-link {
  position: absolute;
  inset: 0;
  display: block;
}
:root {
  --look-card-padding: 4px;
  --product-card-padding: 4px;
}

.look-card {
  aspect-ratio: 0.7352;
  position: relative;
  width: 100%;
  z-index: 1;
}

.look-card-hidden {
  background-color: rgba(29, 29, 29, 0.4);
  aspect-ratio: 0.7352;
  border-radius: 0.5rem;
  position: absolute;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.look-card-hidden .icon {
  width: 2rem;
  height: 2rem;
  color: #f0f0f0;
}

.look-card-link {
  position: absolute;
  inset: 0;
  display: block;
}

.look-card-products {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: transparent;
  display: grid;
  grid-gap: 0.125rem;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-flow: dense;
}
.look-card-products .look-card-product-tr {
  border-top-right-radius: 0.5rem;
}
.look-card-products .look-card-product-tl {
  border-top-left-radius: 0.5rem;
}
.look-card-products .look-card-product-br {
  border-bottom-right-radius: 0.5rem;
}
.look-card-products .look-card-product-bl {
  border-bottom-left-radius: 0.5rem;
}

.look-card-products-empty {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f0f0f0;
  aspect-ratio: 0.7352;
  border-radius: 0.5rem;
  gap: 12px;
}
.look-card-products-empty .icon {
  width: 32px;
  height: 32px;
  color: #BFBFBF;
}
.look-card-products-empty p {
  color: #434343;
  font-size: 14px;
  font-weight: 300;
  max-width: 50%;
  text-align: center;
  line-height: 18px;
}

.look-card-product {
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.06);
  border-width: 1px;
  background-color: #fff;
  padding: 4px;
  overflow: hidden;
  position: relative;
}

.look-card-product-footer {
  padding: 0 4px 4px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 1.125rem;
  display: flex;
  gap: 0.25rem;
  justify-content: space-between;
}

.look-card-product-brand {
  font-size: 0.7rem;
  line-height: 1.125rem;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: uppercase;
}

.look-card-product-retailer {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.65rem;
  height: 1.55rem;
  opacity: 0;
  transition: opacity 0.2s;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.look-card-product:hover .look-card-product-retailer {
  opacity: 1;
}

.look-card-product-price {
  font-size: 0.7rem;
  line-height: 1.125rem;
  white-space: nowrap;
}

.look-card-product-price-old {
  text-decoration: line-through;
}

.look-card-product-price-discounted {
  color: #c71212;
}

.look-card-product-reaction {
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
}
.look-card-product-reaction .icon {
  width: 1rem;
  height: 1rem;
}

.look-card-published {
  position: absolute;
  z-index: 1;
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  background-color: rgba(0, 102, 0, 0.5);
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  top: -0.25rem;
  right: -0.25rem;
}
.look-card-published .icon {
  width: 0.5rem;
  height: 0.5rem;
}
.look-dialog .ant-modal {
  max-width: 75vw;
  min-width: 75vw;
}
.look-dialog .ant-modal .ant-modal-content {
  min-height: 50vh;
  position: relative;
}
.look-dialog .ant-modal .ant-modal-body > .loading {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.look-dialog-close {
  display: block;
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  right: 1rem;
  top: 1rem;
  color: #1d1d1d;
  z-index: 1;
}
.look-dialog-close .icon {
  width: 1rem;
  height: 1rem;
}

.look-dialog-look {
  display: flex;
  gap: 1rem;
}

.look-dialog-images {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}
.look-dialog-images .image-slider {
  width: 100%;
  height: 100%;
}

.look-dialog-info {
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.look-dialog-info label {
  font-size: 0.75rem;
  font-weight: 500;
  text-transform: uppercase;
}
.look-dialog-info h4 {
  font-weight: 500;
  font-size: 2rem;
  line-height: 2rem;
}
.look-dialog-info p.look-dialog-product-description {
  font-size: 1rem;
}
.look-dialog-info p.look-dialog-product-price {
  font-weight: 500;
  font-size: 1rem;
}
.look-dialog-info p.look-dialog-product-price span + span {
  margin-left: 6px;
  color: rgba(0, 0, 0, 0.3);
  text-decoration: line-through;
}

.look-dialog-actions {
  margin-top: 1rem;
  display: flex;
  gap: 0.5rem;
  justify-content: center;
  border-top: 1px solid rgba(0, 0, 0, 0.06);
  padding-top: 1rem;
}
.look-dialog-actions .ant-btn:first-child {
  margin-right: auto;
}

.look-dialog-products {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
}
.look-dialog-products .catalogue-product-card-retailer {
  font-size: 0.6rem;
  /*&.len-8 {
      font-size: 0.55rem;
  }*/
}
.look-dialog-products .catalogue-product-card-retailer.len-16 span {
  font-size: 1em;
}
.look-dialog-products .catalogue-product-card {
  max-width: calc(20% - 0.5rem);
}
.look-dialog-products .catalogue-product-card.active .catalogue-product-card-image {
  border: 1px solid #1d1d1d;
}

.look-dialog-customer-actions {
  display: flex;
  gap: 0.5rem;
  border-top: 1px solid rgba(0, 0, 0, 0.06);
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.look-dialog-customer-actions .ant-btn {
  flex: 1;
}

.look-dialog-comment {
  border-top: 1px solid rgba(0, 0, 0, 0.06);
  padding-top: 1rem;
}
.look-dialog-comment .ant-space-compact {
  width: 100%;
}

.look-dialog-last-message {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  border-top: 1px solid rgba(0, 0, 0, 0.06);
  padding-top: 0.5rem;
}
.look-dialog-last-message a {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  width: 1.5rem;
  height: 1.5rem;
}
.look-dialog-last-message .icon {
  width: 1rem;
  height: 1rem;
}
.looks-dialog.empty .ant-modal {
  height: 265px;
}
.looks-dialog .ant-modal {
  height: 500px;
}
.looks-dialog .ant-modal-content {
  padding: 1.375rem 0 0 0;
  height: 100%;
  border-radius: 0;
}
.looks-dialog .ant-modal-body {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}
.looks-dialog-body {
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 1rem;
}
.looks-dialog .feed-dialog-close {
  margin-left: auto;
}
.looks-dialog .looks-cards {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  overflow: auto;
  padding: 4px 1.375rem;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.looks-dialog .looks-cards::-webkit-scrollbar {
  display: none;
}
.looks-dialog .toolbar {
  width: 100%;
  padding: 0 1.375rem;
}
.looks-dialog .toolbar .ant-input-search {
  flex: 1;
}
.looks-dialog .page-styling-menu {
  margin: 0 16px;
}
.looks-dialog .look-card, .looks-dialog .look-card-tagged {
  cursor: pointer;
  width: 190px;
  height: 256px;
  border-radius: 0.5rem;
}
.looks-dialog .look-card-image, .looks-dialog .look-card-tagged-image {
  border-radius: 0.5rem;
}
.looks-dialog .look-item {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.looks-dialog .look-footer {
  display: flex;
  flex-direction: column;
}
.looks-dialog .look-footer-counts {
  font-size: 14px;
  font-weight: 300;
  display: flex;
  gap: 5px;
}
.looks-dialog .look-footer-count {
  display: flex;
  align-items: center;
  gap: 5px;
}
.looks-dialog .look-footer-count svg {
  width: 1rem;
  height: 1rem;
}
.looks-dialog .look-footer-date {
  margin-left: auto;
}
.looks-dialog .look-footer-title {
  text-transform: uppercase;
  font-weight: 700;
}
.looks-dialog .looks-cards-empty {
  margin: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  font-size: 20px;
}
.looks-dialog .looks-cards-empty p {
  font-size: 20px;
  max-width: 50%;
  text-align: center;
  line-height: 26px;
}
.looks-dialog .looks-cards-empty-action {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  margin: 20px 0 24px 0;
}
.bank-details-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.bank-details-form .form-input-label {
  font-weight: 500;
}

.bank-details-info {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.bank-details-info p {
  font-size: 1rem;
}
.change-avatar-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.change-avatar-form-actions {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
}
.change-avatar-form-actions button {
  flex: 1;
}

.change-avatar-form-dropzone {
  aspect-ratio: 1;
  width: 100%;
  border: 1px solid #bfbfbf;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  overflow: hidden;
}
.change-avatar-form-dropzone img {
  position: absolute;
  top: 50%;
  left: 50%;
  cursor: move;
}
.change-avatar-form-dropzone img.vertical {
  width: 100%;
}
.change-avatar-form-dropzone img.horizontal {
  height: 100%;
}
.change-avatar-form-dropzone img.square {
  width: 100%;
}
.change-avatar-form-dropzone input {
  width: 40px;
  height: 40px;
}

.change-avatar-form-dropzone-icon {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
  font-size: 0.875rem;
}
.change-avatar-form-dropzone-icon .icon {
  width: 2rem;
  height: 2rem;
}
.change-password-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.change-password-form .form-input .ant-typography:first-child {
  font-weight: 500;
}
.connection-selector {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  height: 100%;
}
.connection-selector form {
  padding: 0 4px;
}
.connection-selector button.button {
  margin: 0 1rem;
  min-height: 36px;
}
.connection-selector .input-checkbox {
  margin: 0.5rem auto;
}

.connection-selector-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 24px 4px;
  height: 100%;
  overflow: auto;
}

.connection-selector-list-item {
  display: flex;
  justify-content: space-between;
  min-height: 64px;
  align-items: center;
  padding: 0.5rem 1rem;
  gap: 0.5rem;
  border-radius: 0.5rem;
  box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.16);
}
.connection-selector-list-item div {
  gap: 0.5rem;
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 500;
}

.connection-selector-header {
  display: flex;
  justify-content: space-between;
}
.connection-selector-header h4 {
  font-weight: 500;
}
.connection-selector-header .icon {
  width: 1rem;
  height: 1rem;
}

.connection-selector-header-text {
  text-align: center;
  max-width: 70%;
  margin: auto;
}

.connection-selector-form-text {
  margin-bottom: 0.5rem;
}
.edit-user-details-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.edit-user-details-form .form-input-label {
  font-weight: 500;
}
.edit-user-details-form textarea {
  min-height: 85px;
}
.feed-dialog .ant-modal {
  width: 66.5rem;
  height: 80vh;
}
.feed-dialog .ant-modal > div {
  width: 100%;
  height: 80vh;
}
.feed-dialog .ant-modal-content {
  width: 100%;
  height: 80vh;
  border-radius: 0;
  padding: 0;
}
.feed-dialog .ant-modal-body {
  width: 100%;
  height: 80vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.feed-dialog .catalogue-side-filters {
  padding-left: 1rem;
  padding-top: 1rem;
}
.feed-dialog .feed-messages {
  gap: 0.5rem;
}
.feed-dialog .feed-messages .feed-message h3 {
  font-weight: 1rem;
}

.feed-dialog-header {
  padding: 0 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.feed-dialog-header > div {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}
.feed-dialog-header .ant-select {
  min-width: 0;
  width: auto;
  flex: 1;
}
.feed-dialog-header .ant-select.selector-region {
  flex: 1.5;
}

.feed-dialog-layout {
  height: calc(80vh - 56px);
}

.feed-dialog-footer {
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.feed-dialog-body {
  overflow: auto;
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.feed-dialog-close {
  color: #bfbfbf;
  transition: color 0.2s;
  width: 1.25rem;
  height: 1.25rem;
  display: block;
}
.feed-dialog-close:hover {
  color: #1d1d1d;
}
.feed-dialog-close .icon {
  width: 1.25rem;
  height: 1.25rem;
}

.feed-dialog-products {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1rem;
  gap: 1.25rem 1rem;
  justify-content: center;
  align-items: flex-start;
}
.notifications-dialog .ant-list-item:first-of-type {
  padding-top: 0;
}
.notifications-dialog .ant-list-item {
  padding: 0.5rem 1rem;
}
.notifications-dialog .ant-drawer-body {
  padding: 0.5rem 0;
}

.notifications-list {
  max-width: 350px;
  height: 400px;
  max-height: 400px;
  overflow: auto;
  padding-right: 1rem;
  padding-left: 1rem;
}

.notifications-dialog-item {
  justify-content: space-between;
  flex: 1;
}

.notifications-dialog-item-content {
  flex: 1;
}

.notifications-dialog-item-read, .notifications-dialog-item-unread {
  display: block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  transition: border-color 0.2s;
}

.notifications-dialog-item-read {
  visibility: hidden;
}

.notifications-dialog-item-unread {
  background-color: #1d1d1d;
}

.notifications-dialog-item-text {
  color: #1d1d1d;
  display: block;
  font-size: 12px;
  font-weight: 500;
}

.notifications-dialog-item-date {
  font-size: 12px;
  color: #8c8c8c;
  font-weight: 300;
}

.notifications-dialog-item-group {
  min-width: 16px;
  height: 16px;
  font-size: 0.6rem;
  border: 1px solid rgba(0, 0, 0, 0.06);
  text-align: center;
  line-height: 0.6rem;
  box-shadow: 2px 2px 0px 0px rgba(0, 0, 0, 0.05);
  color: #8c8c8c;
  display: flex;
  align-items: center;
  justify-content: center;
}

.notifications-dialog-item-action {
  min-width: 16px;
  height: 16px;
  border: 1px solid rgba(0, 0, 0, 0.06);
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 2px 2px 0px 0px rgba(0, 0, 0, 0.05);
  color: rgba(0, 0, 0, 0.06);
}
.notifications-dialog-item-action .icon {
  width: 10px;
  height: 10px;
  color: #1d1d1d;
}

.notifications-dialog-item-subtext {
  font-size: 0.9rem;
  color: #1d1d1d;
  font-weight: 300;
  white-space: normal;
  display: block;
}
.verify-email-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.verify-email-form .form-input-label {
  font-weight: 500;
}
.button {
  transition: opacity 0.2s, background-color 0.2s, border-radius 0.2s, border-color 0.2s, color 0.2s;
  font-family: "HelveticaNeue";
  cursor: pointer;
  font-size: 0;
  padding: 0;
}

.button--full-width {
  width: 100%;
}

.button--disabled {
  pointer-events: none;
}

.button--danger.button--circle.button--icon-w-text span.button__icon {
  border: 1px solid #F5222D;
  color: #F5222D;
}
.button--danger.button--circle.button--icon-w-text span.button__text.button__text--danger {
  color: #F5222D;
}

.button__icon {
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  transition: opacity 0.2s, background-color 0.2s, border-radius 0.2s, border-color 0.2s, color 0.2s;
}

.button__text {
  transition: opacity 0.2s, background-color 0.2s, border-radius 0.2s, border-color 0.2s, color 0.2s;
}

.button--primary {
  background-color: #1d1d1d;
  border: 1px solid #1d1d1d;
  color: #fff;
}
.button--primary:hover {
  background-color: #373737;
  border-color: #373737;
  color: #fff;
}
.button--primary.button--disabled {
  background-color: #505050;
  border-color: #505050;
  color: #fff;
}

.button--inverted {
  background-color: #fff;
  color: #1d1d1d;
  border: none;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
}

.button--secondary {
  background-color: #fff;
  border: 1px solid #1d1d1d;
  color: #1d1d1d;
}
.button--secondary:hover {
  background-color: #f2f2f2;
  color: #1d1d1d;
}
.button--secondary.button--disabled {
  background-color: #f2f2f2;
  color: #838383;
  border-color: #838383;
}

.button--text {
  background-color: transparent;
  border: 1px solid transparent;
  color: #1d1d1d;
}
.button--text.button--disabled {
  color: #9d9d9d;
}

.button--center {
  justify-content: center;
}

.button--default-shape {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  overflow: hidden;
  min-width: 0;
  transition: -webkit-transform 0.4s;
  transition: transform 0.4s;
  transition: transform 0.4s, -webkit-transform 0.4s;
}
.button--default-shape:active {
  -webkit-transform: translateY(2px);
          transform: translateY(2px);
}

.button--circle {
  border-radius: 50%;
  transition: -webkit-transform 0.4s;
  transition: transform 0.4s;
  transition: transform 0.4s, -webkit-transform 0.4s;
}
.button--circle:active {
  -webkit-transform: translateY(2px);
          transform: translateY(2px);
}

.button--small {
  height: 1.5rem;
}
.button--small .button__text {
  font-size: 0.75rem;
  font-weight: 300;
  letter-spacing: 0.12px;
}

.button--medium {
  height: 1.75rem;
}
.button--medium .button__text {
  font-size: 0.875rem;
  font-weight: 300;
  letter-spacing: 0.14px;
}

.button--large {
  height: 2.25rem;
}
.button--large .button__text {
  font-size: 0.875rem;
  font-weight: 300;
  letter-spacing: 0.14px;
}

.button--default-shape .button__text {
  font-weight: 400;
  text-transform: uppercase;
}

.button--default-shape.button--small {
  border-radius: 0.25rem;
  padding-left: 0.875rem;
  padding-right: 0.875rem;
}

.button--default-shape.button--medium {
  border-radius: 0.5rem;
  padding-left: 0.875rem;
  padding-right: 0.875rem;
}

.button--default-shape.button--large {
  border-radius: 0.5rem;
  padding-left: 0.875rem;
  padding-right: 0.875rem;
}

.button__icon-svg--small {
  width: 0.75rem;
  height: 0.75rem;
}

.button__icon-svg--medium {
  width: 0.875rem;
  height: 0.875rem;
}

.button__icon-svg--large {
  width: 0.875rem;
  height: 0.875rem;
}

.button__icon-svg--xlarge {
  width: 1.5rem;
  height: 1.5rem;
}

.button__text--default-shape {
  white-space: nowrap;
}

.button--icon-only.button--circle {
  display: flex;
  justify-content: center;
  align-items: center;
}

.button__icon--icon-only.button__icon--circle {
  display: block;
}

.button--icon-only.button--circle.button--small {
  width: 1.5rem;
  height: 1.5rem;
}

.button--icon-only.button--circle.button--medium {
  width: 1.75rem;
  height: 1.75rem;
}

.button--icon-only.button--circle.button--large {
  width: 2.25rem;
  height: 2.25rem;
}

.button--icon-only.button--circle.button--xlarge {
  width: 3rem;
  height: 3rem;
}

.button__icon--icon-only.button__icon--circle.button__icon--medium {
  width: 0.875rem;
  height: 0.875rem;
}

.button__icon-svg--icon-only.button__icon-svg--circle.button__icon-svg--large {
  width: 0.875rem;
  height: 0.875rem;
}

.button--secondary.button--circle.button--icon-w-text,
.button--primary.button--circle.button--icon-w-text {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  height: auto;
  width: -webkit-min-content;
  width: min-content;
  gap: 0.5rem;
  background-color: transparent;
  border: 1px solid transparent;
}
.button--secondary.button--circle.button--icon-w-text .button__icon,
.button--primary.button--circle.button--icon-w-text .button__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
}
.button--secondary.button--circle.button--icon-w-text .button__text,
.button--primary.button--circle.button--icon-w-text .button__text {
  letter-spacing: 0.24px;
  line-height: 1rem;
}

.button--text.button--circle.button--icon-w-text {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  gap: 0.5rem;
  background-color: transparent;
}
.button--text.button--circle.button--icon-w-text .button__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.25);
}
.button--text.button--circle.button--icon-w-text .button__text {
  white-space: nowrap;
}

.button__icon--icon-w-text.button__icon--circle.button__icon--medium {
  width: 1.75rem;
  height: 1.75rem;
}

.button__icon-svg--icon-w-text.button__icon-svg--circle.button__icon-svg--medium {
  width: 0.875rem;
  height: 0.875rem;
}

.button__icon--icon-w-text.button__icon--circle.button__icon--lmedium {
  width: 2rem;
  height: 2rem;
}

.button__icon-svg--icon-w-text.button__icon-svg--circle.button__icon-svg--lmedium {
  width: 1rem;
  height: 1rem;
}

.button__icon--icon-w-text.button__icon--circle.button__icon--xlarge {
  width: 3rem;
  height: 3rem;
}

.button__icon-svg--icon-w-text.button__icon-svg--circle.button__icon-svg--xlarge {
  width: 1.5rem;
  height: 1.5rem;
}

.button.button--circle.button--icon-w-text .button__icon--secondary {
  background-color: #fff;
  border: 1px solid #fff;
  color: #1d1d1d;
}
.button.button--circle.button--icon-w-text .button__icon--primary {
  background-color: #1d1d1d;
  border: 1px solid #1d1d1d;
  color: #fff;
}
.button.button--circle.button--icon-w-text:hover .button__icon--text {
  background-color: #fafafa;
}
.button.button--circle.button--icon-w-text:hover .button__icon--secondary {
  background-color: #fafafa;
  border-color: #fafafa;
  color: #1d1d1d;
}
.button.button--circle.button--icon-w-text:hover .button__icon--primary {
  background-color: #373737;
  border-color: #373737;
  color: #fff;
}
.button.button--circle.button--icon-w-text.button--disabled .button__icon--secondary {
  background-color: #fafafa;
  border-color: #fafafa;
  color: #9d9d9d;
}
.button.button--circle.button--icon-w-text.button--disabled .button__icon--primary {
  background-color: #505050;
  border-color: #505050;
  color: #fff;
}
.button.button--circle.button--icon-w-text .button__text.button__text--primary {
  color: #1d1d1d;
}
.button.button--circle.button--icon-w-text .button__text.button__text--secondary {
  color: #1d1d1d;
}
.button.button--circle.button--icon-w-text .button__text.button__text--disabled {
  opacity: 0.5;
}
.button.button--circle.button--icon-w-text .button__text.button__text--medium {
  font-size: 0.75rem;
}
.button.button--circle.button--icon-w-text .button__text.button__text--lmedium {
  font-size: 0.75rem;
}
.button.button--circle.button--icon-w-text .button__text.button__text--xlarge {
  font-size: 0.75rem;
}

.button--text .button__text--link {
  text-decoration: underline;
}

.button.button--circle.button--icon-url .button__icon--icon-url {
  border: 0;
}
.button.button--circle.button--icon-url .button__icon--icon-url.button__icon--disabled {
  border: 0;
}

.button--icon-url.button--disabled .button__icon::after {
  content: "";
  position: absolute;
  display: block;
  background-color: rgba(255, 255, 255, 0.5);
  inset: 0;
  border-radius: 50%;
}
.button.context-button {
  position: absolute;
  top: 0.65rem;
  right: 0.65rem;
}